import React, { useState } from "react";
import { connect } from "react-redux";
import { setopenmain, selezionacustomization } from "../../state/actions";

import { AiOutlineScissor } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdTexture } from "react-icons/md";
import { GoTextSize } from "react-icons/go";
import { FaRulerHorizontal } from "react-icons/fa";

import { owner } from "../../data";

const NavFeatures = (props) => {
  const { lingua, setOpenMain, setCust } = props;

  const [attivo, setAttivo] = useState(false);
  const [start, setStart] = useState(true);

  const setOpen = (val) => {
    setCust(val);
    setOpenMain("is-active");
  };

  return (
    <div className="is-hidden-widescreen">
      {attivo && (
        <>
          {owner.social.whatsapp !== "" && (
            <div className="nd6 nds">
              <a
                href={owner.social.whatsapp}
                className="button is-rounded is-success"
              >
                <span className="icon is-small" style={{marginRight:'0.3rem'}}>
                  <IoLogoWhatsapp />
                </span>
                Whatsapp
              </a>
              {/**
              <a
                href={owner.social.whatsapp}
                className="has-tooltip-right has-tooltip-active has-text-white-ter"
                data-tooltip="Whatsapp"
              >
                <IoLogoWhatsapp />
              </a>
               */}
            </div>
          )}

          <button
            className="nd5 nds button is-rounded has-background-white-ter"
            onClick={() => {
              setOpen("tessuti");
              setAttivo(false);
            }}
          >
            <span className="icon is-small" style={{marginRight:'0.3rem'}}>
              <MdTexture />
            </span>
            {lingua.navigazione.tessuto}
          </button>
          <button
            className="nd4 nds button is-rounded has-background-white-ter"
            onClick={() => {
              setOpen("personalizzazione");
              setAttivo(false);
            }}
          >
            <span className="icon is-small" style={{marginRight:'0.3rem'}}>
              <AiOutlineScissor />
            </span>
            {lingua.navigazione.personalizza}
          </button>
          <button
            className="nd3 nds button is-rounded has-background-white-ter"
            onClick={() => {
              setOpen("iniziali");
              setAttivo(false);
            }}
          >
            <span className="icon is-small" style={{marginRight:'0.3rem'}}>
              <GoTextSize />
            </span>
            {lingua.navigazione.iniziali.iniziali}
          </button>
          <button
            className="nd1 nds button is-rounded has-background-white-ter"
            onClick={() => {
              setOpen("misure");
              setAttivo(false);
            }}
          >
            <span className="icon is-small" style={{marginRight:'0.3rem'}}>
              <FaRulerHorizontal />
            </span>
            {lingua.navigazione.misure.misure}
          </button>
        </>
      )}
      <button
        id="floating-button"
        onClick={() => {setAttivo(!attivo);setStart(false)}}
        className="has-tooltip-right has-tooltip-active"
        data-tooltip={start ? "START" : null}
      >
        {attivo ? <p className="plus">-</p> : <p className="plus">+</p>}
      </button>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setCust: (val) => dispatch(selezionacustomization(val)),
    setOpenMain: (val) => dispatch(setopenmain(val)),
  };
}

export default connect(null, mapDispatchToProps)(NavFeatures);
