import React from "react";
import { Link } from "gatsby";
import LanguageSelector from "../LanguageSelector";

import { owner } from "../../data";

const Footer = ({ lingua }) => {
  /*
  const [consento, setConsento] = useState(true);

  useEffect(() => {
    setConsento(JSON.parse(localStorage.getItem("3dshirt_gdpr")) || false);
  }, []);

  const ok = () => {
    setConsento(true);
    localStorage.setItem("3dshirt_gdpr", true);
    localStorage.setItem("3dshirt_analytics", true);
  };
  const approf = () => {
    setConsento(true);
    localStorage.setItem("3dshirt_gdpr", true);
  };
  */

  return (
    <>
      <footer className="is-hidden-touch">
        <div className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-3">
                <aside className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link
                        to="/"
                        className="level-item"
                        activeStyle={{ color: "#5d7e9d" }}
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contact/"
                        className="level-item"
                        activeStyle={{ color: "#5d7e9d" }}
                      >
                        {lingua.footer.contattaci}
                      </Link>
                    </li>
                  </ul>
                </aside>
              </div>

              <div className="column">
                <aside className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link
                        to="/privacy-policy/"
                        className="level-item"
                        activeStyle={{ color: "#5d7e9d" }}
                      >
                        {lingua.footer.privacy}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/cookie-policy/"
                        className="level-item"
                        activeStyle={{ color: "#5d7e9d" }}
                      >
                        {lingua.footer.cookie}
                      </Link>
                    </li>
                    {/** 
                    <li>
                      <Link
                        to="/terms/"
                        className="level-item"
                        activeStyle={{ color: "#5d7e9d" }}
                      >
                        {lingua.footer.termini}
                      </Link>
                    </li>
                    */}
                    {/** 
                    <li>
                      <Link
                        to="/withdrawal/"
                        className="level-item"
                        activeStyle={{ color: "#5d7e9d" }}
                      >
                        {lingua.footer.recesso}
                      </Link>
                    </li>
                  */}
                  </ul>
                </aside>
              </div>

              <div className="column is-narrow">
                <LanguageSelector />
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <p className="is-size-7 has-text-centered has-text-bold">
            &copy; {new Date().getFullYear()} 3D Shirt - {owner.azienda} -{" "}
            {lingua.footer.partita} {owner.piva}
          </p>
        </div>
      </footer>

      {/*
      {consento === false && (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-content">
            <section>
              <div className="notification">
                <button className="delete" onClick={ok}></button>
                <h3 className="title is-4 is-spaced" data-config-id="header">
                  {lingua.banner.titolo}
                </h3>
                <p className="subtitle">{lingua.banner.testo1}</p>

                <div className="field is-grouped">
                  <p className="control">
                    <button
                      className="button is-primary is-radiusless"
                      onClick={ok}
                    >
                      {lingua.banner.acconsento}
                    </button>
                  </p>
                  <p className="control">
                    <Link
                      to="/cookie-policy/"
                      className="button is-radiusless"
                      activeStyle={{ color: "#666" }}
                      onClick={approf}
                    >
                      {lingua.footer.cookie}
                    </Link>
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
      */}
    </>
  );
};

export default Footer;
