import React from "react"
import { Link } from "gatsby"

const FilterShop = props => {
  const { cat, count, lin } = props

  return (
    <aside
      className="menu shop-menu"
      style={{ position: "sticky", top: "0.25rem" }}
    >
      <ul className="menu-list">
        <li style={{ borderBottom: "1px solid #ededed" }}>
          <Link
            to={"/shop/"}
            style={{ padding: ".75em .75em" }}
            activeClassName="is-active"
            partiallyActive={true}
          >
            Tutti i prodotti ({count})
          </Link>
        </li>

        {cat.edges.map(ca =>
          ca.node.data.prodotti.length ? (
            <li
              key={ca.node.data.id}
              style={{ borderBottom: "1px solid #ededed" }}
            >
              <Link
                to={"/cat/" + ca.node.recordId + "/"}
                style={{ padding: ".75em .75em" }}
                activeClassName="is-active"
              >
                {ca.node.data[`Nome_${lin.substring(0, 2).toLowerCase()}`]} (
                {ca.node.data.prodotti.length})
              </Link>
            </li>
          ) : null
        )}
      </ul>
    </aside>
  )
}

export default FilterShop
