import React from "react";
import { connect } from "react-redux";
import {
  setpersonalizzazione,
  setclub,
  setcontrastocolletto,
  setcontrastopolsino,
  setcontrastochiusura,
  setfiltripersonalizza,
  setvista,
  setcameraorbit,
  setfieldofview,
  setmask,
} from "../../state/actions";

import { Scrollbars } from "react-custom-scrollbars";

import Colletti from "../navigazione/colletti";
import Polsini from "../navigazione/polsini";
import Chiusure from "../navigazione/chiusure";
import Taschini from "../navigazione/taschini";
import Contrasti from "../navigazione/contrasti";
import Asole from "../navigazione/asole";
import Bottoni from "../navigazione/bottoni";
//import Fili from "../navigazione/fili";
import Pinces from "../navigazione/pinces";

import { prices } from "../../data";
import { euro } from "../helpers";

const Personalizzazione = (props) => {
  const {
    lingua,
    pers,
    setPers,
    club,
    setClub,
    setColletto,
    setPolsino,
    setChiusura,
    colletto,
    polsino,
    chiusura,
    filtriPersonalizzazione,
    setFiltriPersonalizza,
    setVista,
    setCameraOrbit,
    setFieldOfView,
    mask,
    setMask,
  } = props;

  let personal = <Colletti lingua={lingua} />;

  switch (pers) {
    case "colletti":
      personal = <Colletti lingua={lingua} />;
      break;
    case "polsini":
      personal = <Polsini lingua={lingua} />;
      break;
    case "chiusure":
      personal = <Chiusure lingua={lingua} />;
      break;
    case "taschini":
      personal = <Taschini lingua={lingua} />;
      break;
    case "contrasti":
      personal = <Contrasti lingua={lingua} />;
      break;
    case "asole":
      personal = <Asole lingua={lingua} />;
      break;
    case "bottoni":
      personal = <Bottoni lingua={lingua} />;
      break;
    /*
    case "fili":
      personal = <Fili lingua={lingua} />;
      break;
      */
    case "pinces":
      personal = <Pinces lingua={lingua} />;
      break;

    default:
      personal = <Colletti lingua={lingua} />;
  }

  const setPersonalizzazione = (tipo) => {
    setPers(tipo);
  };

  return (
    <>
      <div className="columns is-gapless is-hidden-mobile has-background-white">
        <div className="column is-narrow container-narrow">
          <aside className="menu section">
            <div className="menu-list" style={{ marginBottom: "1rem" }}>
              <button
                style={{ fontSize: "1rem" }}
                className={
                  pers === "colletti"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
                onClick={() => {
                  setPersonalizzazione("colletti");
                  setVista("intera");
                  setCameraOrbit(45);
                  setFieldOfView(55);
                }}
              >
                {lingua.navigazione.colletto}
              </button>
              <button
                style={{ fontSize: "1rem" }}
                className={
                  pers === "polsini"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
                onClick={() => {
                  setPersonalizzazione("polsini");
                  setVista("intera");
                  setCameraOrbit(180);
                  setFieldOfView(55);
                }}
              >
                {lingua.navigazione.polsino}
              </button>
              <button
                style={{ fontSize: "1rem" }}
                className={
                  pers === "chiusure"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
                onClick={() => {
                  setPersonalizzazione("chiusure");
                  setVista("intera");
                  setCameraOrbit(90);
                  setFieldOfView(55);
                }}
              >
                {lingua.navigazione.chiusura}
              </button>
              <button
                style={{ fontSize: "1rem" }}
                className={
                  pers === "taschini"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
                onClick={() => {
                  setPersonalizzazione("taschini");
                  setVista("intera");
                  setCameraOrbit("357.5deg 76.98deg 0.8935m");
                  setFieldOfView("36.7deg");
                }}
              >
                {lingua.navigazione.taschino}
              </button>
              <button
                style={{ fontSize: "1rem" }}
                className={
                  pers === "pinces"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
                onClick={() => {
                  setPersonalizzazione("pinces");
                  setVista("intera");
                  setCameraOrbit("177.3deg 100.9deg 1.151");
                  setFieldOfView("38deg");
                }}
              >
                {lingua.navigazione.pinces}
              </button>

              <button
                style={{ fontSize: "1rem" }}
                className={
                  pers === "bottoni"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
                onClick={() => {
                  setPersonalizzazione("bottoni");
                  setVista("intera");
                  setCameraOrbit("395.2deg 47.03deg 0.9119m");
                  setFieldOfView("37.59deg");
                }}
              >
                {lingua.navigazione.bottoni}
              </button>

              <button
                style={{ fontSize: "1rem" }}
                className={
                  pers === "asole"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
                onClick={() => {
                  setPersonalizzazione("asole");
                  setVista("intera");
                  setCameraOrbit("376.4deg 75.56deg 0.5129m");
                  setFieldOfView("25deg");
                }}
              >
                {lingua.navigazione.asole}
              </button>

              {/**
              <button
                style={{ fontSize: "1rem" }}
                className={
                  pers === "fili"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
                onClick={() => {
                  setPersonalizzazione("fili");
                  setVista("intera");
                  setCameraOrbit("351deg 94.1deg 0.5129m");
                  setFieldOfView("25deg");
                }}
              >
                {lingua.navigazione.fili}
              </button>
               */}

              <button
                style={{ fontSize: "1rem" }}
                className={
                  pers === "contrasti"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
                onClick={() => {
                  setPersonalizzazione("contrasti");
                  setVista("intera");
                  setCameraOrbit("387.8deg 64.15deg 1.065m");
                  setFieldOfView("45deg");
                }}
              >
                {lingua.navigazione.contrasto}
              </button>
            </div>

            {(pers === "colletti" || pers === "polsini") && (
              <div className="menu-list">
                <div
                  className="panel-block"
                  data-tooltip={
                    prices.club > 0 ? "+ " + euro(prices.club) : null
                  }
                >
                  <div className="field">
                    <input
                      id="idclub"
                      type="checkbox"
                      name="idclub"
                      className="switch"
                      checked={club}
                      onChange={() => setClub(!club)}
                    />
                    <label htmlFor="idclub">Club</label>
                  </div>
                </div>
              </div>
            )}

            {pers === "contrasti" && (
              <div className="menu-list">
                <div
                  className="panel-block"
                  data-tooltip={
                    prices.contrastoColletto > 0
                      ? "+ " + euro(prices.contrastoColletto)
                      : null
                  }
                >
                  <div className="field">
                    <input
                      id="idcolletto"
                      type="checkbox"
                      name="idcolletto"
                      className="switch"
                      checked={colletto}
                      onChange={() => setColletto(!colletto)}
                    />
                    <label htmlFor="idcolletto">
                      {lingua.navigazione.contrastoColletto}
                    </label>
                  </div>
                </div>
              </div>
            )}

            {pers === "contrasti" && (
              <div className="menu-list">
                <div
                  className="panel-block"
                  data-tooltip={
                    prices.contrastoPolsino > 0
                      ? "+ " + euro(prices.contrastoPolsino)
                      : null
                  }
                >
                  <div className="field">
                    <input
                      id="idpolsino"
                      type="checkbox"
                      name="idpolsino"
                      className="switch"
                      checked={polsino}
                      onChange={() => setPolsino(!polsino)}
                    />
                    <label htmlFor="idpolsino">
                      {lingua.navigazione.contrastoPolsino}
                    </label>
                  </div>
                </div>
              </div>
            )}

            {pers === "contrasti" && (
              <div className="menu-list">
                <div
                  className="panel-block"
                  data-tooltip={
                    prices.contrastoChiusura > 0
                      ? "+ " + euro(prices.contrastoChiusura)
                      : null
                  }
                >
                  <div className="field">
                    <input
                      id="idchiusura"
                      type="checkbox"
                      name="idchiusura"
                      className="switch"
                      checked={chiusura}
                      onChange={() => setChiusura(!chiusura)}
                    />
                    <label htmlFor="idchiusura">
                      {lingua.navigazione.contrastoChiusura}
                    </label>
                  </div>
                </div>
              </div>
            )}

            <div className="menu-list">
              <div
                className="panel-block"
                data-tooltip={
                  prices.mascherina > 0 ? "+ " + euro(prices.mascherina) : null
                }
              >
                <div
                  className="field has-tooltip-right"
                  data-tooltip={lingua.navigazione.desMascherina}
                >
                  <input
                    id="idmask"
                    type="checkbox"
                    name="idmask"
                    className="switch"
                    checked={mask}
                    onChange={() => {
                      setMask(!mask);
                      setVista(!mask ? "mask" : "intera");
                    }}
                  />
                  <label htmlFor="idmask">
                    {lingua.navigazione.mascherina}
                  </label>
                </div>
              </div>
            </div>
          </aside>
        </div>

        <div className="column ">
          <div className="container-features">
            <Scrollbars autoHide style={{ height: "calc(100vh - 3.25rem)" }}>
              {personal}
            </Scrollbars>
          </div>
        </div>
      </div>

      <div className="columns is-gapless is-hidden-tablet has-background-white">
        {filtriPersonalizzazione ? (
          <div className="column is-narrow container-narrow">
            <aside className="menu section">
              <div className="menu-list" style={{ marginBottom: "1rem" }}>
                <button
                  style={{ fontSize: "1rem" }}
                  className={
                    pers === "colletti"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                  onClick={() => {
                    setPersonalizzazione("colletti");
                    setFiltriPersonalizza(false);
                    setVista("intera");
                  }}
                >
                  {lingua.navigazione.colletto}
                </button>
                <button
                  style={{ fontSize: "1rem" }}
                  className={
                    pers === "polsini"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                  onClick={() => {
                    setPersonalizzazione("polsini");
                    setFiltriPersonalizza(false);
                    setVista("intera");
                    setcameraorbit("");
                  }}
                >
                  {lingua.navigazione.polsino}
                </button>
                <button
                  style={{ fontSize: "1rem" }}
                  className={
                    pers === "chiusure"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                  onClick={() => {
                    setPersonalizzazione("chiusure");
                    setFiltriPersonalizza(false);
                    setVista("intera");
                  }}
                >
                  {lingua.navigazione.chiusura}
                </button>
                <button
                  style={{ fontSize: "1rem" }}
                  className={
                    pers === "taschini"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                  onClick={() => {
                    setPersonalizzazione("taschini");
                    setFiltriPersonalizza(false);
                    setVista("intera");
                  }}
                >
                  {lingua.navigazione.taschino}
                </button>
                <button
                  style={{ fontSize: "1rem" }}
                  className={
                    pers === "pinces"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                  onClick={() => {
                    setPersonalizzazione("pinces");
                    setFiltriPersonalizza(false);
                    setVista("intera");
                  }}
                >
                  {lingua.navigazione.pinces}
                </button>

                <button
                  style={{ fontSize: "1rem" }}
                  className={
                    pers === "bottoni"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                  onClick={() => {
                    setPersonalizzazione("bottoni");
                    setFiltriPersonalizza(false);
                    setVista("piegata");
                  }}
                >
                  {lingua.navigazione.bottoni}
                </button>

                <button
                  style={{ fontSize: "1rem" }}
                  className={
                    pers === "asole"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                  onClick={() => {
                    setPersonalizzazione("asole");
                    setFiltriPersonalizza(false);
                    setVista("piegata");
                  }}
                >
                  {lingua.navigazione.asole}
                </button>
                {/**
                <button
                  style={{ fontSize: "1rem" }}
                  className={
                    pers === "fili"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                  onClick={() => {
                    setPersonalizzazione("fili");
                    setFiltriPersonalizza(false);
                    setVista("piegata");
                  }}
                >
                  {lingua.navigazione.fili}
                </button>
                */}

                <button
                  style={{ fontSize: "1rem" }}
                  className={
                    pers === "contrasti"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                  onClick={() => {
                    setPersonalizzazione("contrasti");
                    setFiltriPersonalizza(false);
                    setVista("piegata");
                  }}
                >
                  {lingua.navigazione.contrasto}
                </button>
              </div>

              {(pers === "colletti" || pers === "polsini") && (
                <div className="menu-list">
                  <div
                    className="panel-block"
                    data-tooltip={
                      prices.club > 0 ? "+ " + euro(prices.club) : null
                    }
                  >
                    <div className="field">
                      <input
                        id="idclub"
                        type="checkbox"
                        name="idclub"
                        className="switch"
                        checked={club}
                        onChange={() => setClub(!club)}
                      />
                      <label htmlFor="idclub">Club</label>
                    </div>
                  </div>
                </div>
              )}

              {pers === "contrasti" && (
                <div className="menu-list">
                  <div
                    className="panel-block"
                    data-tooltip={
                      prices.contrastoColletto > 0
                        ? "+ " + euro(prices.contrastoColletto)
                        : null
                    }
                  >
                    <div className="field">
                      <input
                        id="idcolletto"
                        type="checkbox"
                        name="idcolletto"
                        className="switch"
                        checked={colletto}
                        onChange={() => setColletto(!colletto)}
                      />
                      <label htmlFor="idcolletto">
                        {lingua.navigazione.contrastoColletto}
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {pers === "contrasti" && (
                <div className="menu-list">
                  <div
                    className="panel-block"
                    data-tooltip={
                      prices.contrastoPolsino > 0
                        ? "+ " + euro(prices.contrastoPolsino)
                        : null
                    }
                  >
                    <div className="field">
                      <input
                        id="idpolsino"
                        type="checkbox"
                        name="idpolsino"
                        className="switch"
                        checked={polsino}
                        onChange={() => setPolsino(!polsino)}
                      />
                      <label htmlFor="idpolsino">
                        {lingua.navigazione.contrastoPolsino}
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {pers === "contrasti" && (
                <div className="menu-list">
                  <div
                    className="panel-block"
                    data-tooltip={
                      prices.contrastoChiusura > 0
                        ? "+ " + euro(prices.contrastoChiusura)
                        : null
                    }
                  >
                    <div className="field">
                      <input
                        id="idchiusura"
                        type="checkbox"
                        name="idchiusura"
                        className="switch"
                        checked={chiusura}
                        onChange={() => setChiusura(!chiusura)}
                      />
                      <label htmlFor="idchiusura">
                        {lingua.navigazione.contrastoChiusura}
                      </label>
                    </div>
                  </div>
                </div>
              )}
              <div className="menu-list">
                <div
                  className="panel-block"
                  data-tooltip={
                    prices.mascherina > 0
                      ? "+ " + euro(prices.mascherina)
                      : null
                  }
                >
                  <div
                    className="field has-tooltip-right"
                    data-tooltip={lingua.navigazione.desMascherina}
                  >
                    <input
                      id="idmask"
                      type="checkbox"
                      name="idmask"
                      className="switch"
                      checked={mask}
                      onChange={() => {
                        setMask(!mask);
                        setVista(!mask ? "mask" : "intera");
                      }}
                    />
                    <label htmlFor="idmask">
                      {lingua.navigazione.mascherina}
                    </label>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        ) : (
          <div className="column ">
            <div className="container-features">{personal}</div>
          </div>
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    pers: state.shop.personalizzazione,
    mask: state.shop.camicia.mask,
    club: state.shop.camicia.club,
    colletto: state.shop.camicia.contrastoColletto,
    polsino: state.shop.camicia.contrastoPolsino,
    chiusura: state.shop.camicia.contrastoChiusura,
    filtriPersonalizzazione: state.shop.filtriPersonalizza,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPers: (val) => dispatch(setpersonalizzazione(val)),
    setMask: (val) => dispatch(setmask(val)),
    setClub: (val) => dispatch(setclub(val)),
    setColletto: (val) => dispatch(setcontrastocolletto(val)),
    setPolsino: (val) => dispatch(setcontrastopolsino(val)),
    setChiusura: (val) => dispatch(setcontrastochiusura(val)),
    setFiltriPersonalizza: (val) => dispatch(setfiltripersonalizza(val)),
    setVista: (val) => dispatch(setvista(val)),
    setCameraOrbit: (val) => dispatch(setcameraorbit(val)),
    setFieldOfView: (val) => dispatch(setfieldofview(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Personalizzazione);
