import React from "react"
import { Link } from "gatsby"

import { euro } from "../../helpers"

import Img from "gatsby-image"

const ShopRelated = props => {
  const { items, langu, titolo } = props

  return (
    <>
      <p className="title is-spaced is-4">{titolo}</p>

      <div className="columns is-multiline">
        {items.map(item => (
          <Link
            to={`/${item.data.slug}`}
            key={item.data.ID}
            className="column is-3"
          >
            <div className="card is-shadowless">
              <div className="card-image">
                {item.data.Immagine.localFiles.length ? (
                  <Img
                    fluid={
                      item.data.Immagine.localFiles[0].childImageSharp.fluid
                    }
                  />
                ) : null}
              </div>
              <div className="card-content" style={{ marginTop: "auto" }}>
                <p className="is-size-6 has-text-weight-bold">
                  {item.data[`Nome_${langu.substring(0, 2).toLowerCase()}`]}
                </p>
                <p
                  style={{ marginBottom: "1rem" }}
                  className="is-size-5 has-text-weight-semibold"
                >
                  {item.data.PrezzoOfferta > 0 && (
                    <s
                      className="is-size-6"
                      style={{ marginRight: "1rem", opacity: "0.5" }}
                    >
                      {euro(item.data.PrezzoListino)}
                    </s>
                  )}
                  {item.data.PrezzoOfferta > 0
                    ? euro(item.data.PrezzoOfferta)
                    : euro(item.data.PrezzoListino)}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  )
}

export default ShopRelated
