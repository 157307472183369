import React, { useState } from "react";
import Card from "../../components/card";

const PrintCards = (props) => {
  const { cards, lingua, corrente } = props;

  const [visibile, setVisibile] = useState(true);

  return (
    <div className="section">
      <div className="row columns is-multiline is-mobile">
        {cards.length > 0
          ? cards.map(({ node }) => (
              <div
                className="column is-half-mobile is-half-tablet is-half-desktop is-full-widescreen is-half-fullhd "
                key={node.data._id}
              >
                <Card
                  tessuto={node}
                  lingua={lingua}
                  attivo={node.data.codice === corrente ? true : false}
                />
              </div>
            ))
          : visibile && (
              <div className="notification is-primary is-light">
                <button
                  className="delete"
                  onClick={() => setVisibile(false)}
                ></button>
                {lingua.navigazione.notfound}
              </div>
            )}
      </div>
    </div>
  );
};

export default PrintCards;
