import React, { useState } from "react";
import { connect } from "react-redux";
import Img from "gatsby-image";
import { Link, graphql } from "gatsby";

import { lingua_it } from "../i18n/it-IT";
import { lingua_en } from "../i18n/en-GB";
import { lingua_es } from "../i18n/es-ES";
import { lingua_fr } from "../i18n/fr-FR";

import Seo from "../components/seo";

import { AiOutlineCheckCircle } from "react-icons/ai";

const IndexPage = (props) => {
  const { data, lang } = props;

  let lingua = {};

  switch (lang) {
    case "it-IT":
      lingua = lingua_it;
      break;
    case "fr-FR":
      lingua = lingua_fr;
      break;
    case "es-ES":
      lingua = lingua_es;
      break;
    case "en-GB":
      lingua = lingua_en;
      break;
    default:
      lingua = lingua_it;
  }

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  return (
    <div>
      <Seo
        title={lingua.sito.titolo}
        keywords={[
          `3D Shirt`,
          `configuratore 3d per camicie`,
          `camicie su misura`,
        ]}
        description={lingua.sito.sottotitolo}
      />

      <section className="section is-medium" style={{ background: "#eaeae8" }}>
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column  is-8-desktop is-offset-2-desktop has-text-centered">
              <h1 className="title is-size-3-mobile is-2 is-spaced ">
                {lingua.sito.titolo}
              </h1>
              <h2 className="subtitle is-5 ">{lingua.sito.sottotitolo}</h2>

              <p className="has-text-centered" style={{ marginTop: "2.5rem" }}>
                <Link
                  to="/tessuto00011"
                  className=" button has-text-weight-bold is-dark is-large is-radiusless"
                >
                  {lingua.sito.bottone1}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="section is-medium">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <div className="ls-section-content has-text-centered ls-space-bottom">
                <h3 className="title has-text-dark">{lingua.sito.titolo2}</h3>
                <p
                  className="content has-text-grey is-size-5"
                  style={{ marginBottom: "3rem" }}
                >
                  {lingua.sito.sottotitolo2}
                </p>
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column" style={{ marginBottom: "1.5rem" }}>
              <Img fluid={data.shot1.childImageSharp.fluid} alt="3d shirt" />
            </div>
          </div>
          <div className="columns">
            <div className="column" style={{ marginBottom: "1.5rem" }}>
              <Img fluid={data.shot2.childImageSharp.fluid} alt="3d shirt" />
            </div>
          </div>
          <div className="columns">
            <div className="column" style={{ marginBottom: "1.5rem" }}>
              <Img fluid={data.shot3.childImageSharp.fluid} alt="3d shirt" />
            </div>
          </div>
          <div className="columns">
            <div className="column" style={{ marginBottom: "1.5rem" }}>
              <Img fluid={data.shot4.childImageSharp.fluid} alt="3d shirt" />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <Img fluid={data.shot5.childImageSharp.fluid} alt="3d shirt" />
            </div>
          </div>
        </div>
      </div>

      <div
        className="section is-medium"
        style={{ backgroundColor: "whitesmoke" }}
      >
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <div className="ls-section-content has-text-centered ls-space-bottom">
                <h3 className="title has-text-dark">{lingua.sito.titolo3}</h3>
                <p
                  className="content has-text-grey is-size-5"
                  style={{ marginBottom: "3rem" }}
                >
                  {lingua.sito.sottotitolo3}
                </p>
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column is-one-third">
              <div className="media">
                <span className="media-left" style={{ fontSize: "28px" }}>
                  <AiOutlineCheckCircle />
                </span>
                <div className="media-content">
                  <h4
                    className="level title is-size-6 is-size-4-widescreen"
                    style={{ marginBottom: "0.6rem" }}
                  >
                    {lingua.sito.titolo4}
                  </h4>
                  <p>{lingua.sito.sottotitolo4}</p>
                </div>
              </div>
            </div>

            <div className="column is-one-third">
              <div className="media">
                <span className="media-left" style={{ fontSize: "28px" }}>
                  <AiOutlineCheckCircle />
                </span>
                <div className="media-content">
                  <h4
                    className="level title is-size-6 is-size-4-widescreen"
                    style={{ marginBottom: "0.6rem" }}
                  >
                    {lingua.sito.titolo5}
                  </h4>
                  <p>{lingua.sito.sottotitolo5}</p>
                </div>
              </div>
            </div>

            <div className="column is-one-third">
              <div className="media">
                <span className="media-left" style={{ fontSize: "28px" }}>
                  <AiOutlineCheckCircle />
                </span>
                <div className="media-content">
                  <h4
                    className="level title is-size-6 is-size-4-widescreen"
                    style={{ marginBottom: "0.6rem" }}
                  >
                    {lingua.sito.titolo6}
                  </h4>
                  <p>{lingua.sito.sottotitolo6}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column is-one-third">
              <div className="media">
                <span className="media-left" style={{ fontSize: "28px" }}>
                  <AiOutlineCheckCircle />
                </span>
                <div className="media-content">
                  <h4
                    className="level title is-size-6 is-size-4-widescreen"
                    style={{ marginBottom: "0.6rem" }}
                  >
                    {lingua.sito.titolo7}
                  </h4>
                  <p>{lingua.sito.sottotitolo7}</p>
                </div>
              </div>
            </div>

            <div className="column is-one-third">
              <div className="media">
                <span className="media-left" style={{ fontSize: "28px" }}>
                  <AiOutlineCheckCircle />
                </span>
                <div className="media-content">
                  <h4
                    className="level title is-size-6 is-size-4-widescreen"
                    style={{ marginBottom: "0.6rem" }}
                  >
                    {lingua.sito.titolo8}
                  </h4>
                  <p>{lingua.sito.sottotitolo8}</p>
                </div>
              </div>
            </div>

            <div className="column is-one-third">
              <div className="media">
                <span className="media-left" style={{ fontSize: "28px" }}>
                  <AiOutlineCheckCircle />
                </span>
                <div className="media-content">
                  <h4
                    className="level title is-size-6 is-size-4-widescreen"
                    style={{ marginBottom: "0.6rem" }}
                  >
                    {lingua.sito.titolo9}
                  </h4>
                  <p>{lingua.sito.sottotitolo9}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section is-medium " style={{ paddingBottom: "1rem" }}>
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <div className="ls-section-content has-text-centered ls-space-bottom">
                <h3 className="title">{lingua.sito.titolo10}</h3>
                <p
                  className="content is-size-5"
                  style={{ marginBottom: "3rem" }}
                >
                  {lingua.sito.sottotitolo10}
                </p>
              </div>
            </div>
          </div>
          <div className="columns is-centered is-vcentered">
            <div className="column pr-6">
              <h3 className="title is-size-6 is-size-4-widescreen is-spaced mb-3">
                {lingua.sito.titolo11}
              </h3>
              <p className="subtitle">{lingua.sito.sottotitolo11}</p>
            </div>
            <div className="column">
              <div className="" style={{ position: "relative" }}>
                <Img
                  className="is-relative is-fullwidth is-object-cover"
                  fluid={data.shot7.childImageSharp.fluid}
                  alt="3d shirt"
                />
              </div>
            </div>
          </div>

          <div className="columns is-centered is-vcentered is-hidden-tablet mt-3">
            <div className="column pr-6 ">
              <h3 className="title is-size-6 is-size-4-widescreen is-spaced mb-3">
                {lingua.sito.titolo12}
              </h3>
              <p className="subtitle">{lingua.sito.sottotitolo12}</p>
            </div>
            <div className="column">
              <div className="" style={{ position: "relative" }}>
                <Img
                  className="is-relative is-fullwidth is-object-cover"
                  fluid={data.shot6.childImageSharp.fluid}
                  alt="3d shirt"
                />
              </div>
            </div>
          </div>

          <div className="columns is-centered is-vcentered is-hidden-mobile mt-3">
            <div className="column">
              <div className="" style={{ position: "relative" }}>
                <Img
                  className="is-relative is-fullwidth is-object-cover"
                  fluid={data.shot6.childImageSharp.fluid}
                  alt="3d shirt"
                />
              </div>
            </div>
            <div className="column pl-6 ">
              <h3 className="title is-size-6 is-size-4-widescreen is-spaced mb-3">
                {lingua.sito.titolo12}
              </h3>
              <p className="subtitle">{lingua.sito.sottotitolo12}</p>
            </div>
          </div>

          <div className="columns is-centered is-vcentered mt-3">
            <div className="column pr-6">
              <h3 className="title is-size-6 is-size-4-widescreen is-spaced mb-3">
                {lingua.sito.last05}
              </h3>
              <p className="subtitle">{lingua.sito.last06}</p>
            </div>
            <div className="column">
              <div className="" style={{ position: "relative" }}>
                <Img
                  className="is-relative is-fullwidth is-object-cover"
                  fluid={data.shot8.childImageSharp.fluid}
                  alt="3d shirt"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ paddingBottom: 0 }}>
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="box">
                <div className="media">
                  <span className="media-left" style={{ fontSize: "28px" }}>
                    <AiOutlineCheckCircle />
                  </span>
                  <div className="media-content">
                    <h4
                      className="level title is-size-6 is-size-4-widescreen"
                      style={{ marginBottom: "0.6rem" }}
                    >
                      {lingua.sito.last01}
                    </h4>
                    <p>{lingua.sito.last02}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="box">
                <div className="media">
                  <span className="media-left" style={{ fontSize: "28px" }}>
                    <AiOutlineCheckCircle />
                  </span>
                  <div className="media-content">
                    <h4
                      className="level title is-size-6 is-size-4-widescreen"
                      style={{ marginBottom: "0.6rem" }}
                    >
                      {lingua.sito.last03}
                    </h4>
                    <p>{lingua.sito.last04}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section is-medium">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <div className="ls-section-content has-text-centered ls-space-bottom">
                <p
                  className="content has-text-grey is-size-5"
                  style={{ marginBottom: "3rem" }}
                >
                  {lingua.sito.last08}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container is-max-desktop">
          <div className="columns is-vcentered is-variable is-6">
            <div className="column is-6-mobile is-offset-3-mobile">
              <Img
                fluid={data.logo1.childImageSharp.fluid}
                alt="Sartorie Italiane"
              />
            </div>
            <div className="column is-6-mobile is-offset-3-mobile">
              <Img fluid={data.logo2.childImageSharp.fluid} alt="Midena" />
            </div>
            <div className="column is-6-mobile is-offset-3-mobile">
              <Img fluid={data.logo3.childImageSharp.fluid} alt="Turrisi" />
            </div>
            <div className="column is-6-mobile is-offset-3-mobile">
              <Img fluid={data.logo4.childImageSharp.fluid} alt="Su Misura" />
            </div>
            <div className="column is-6-mobile is-offset-3-mobile">
              <Img fluid={data.logo5.childImageSharp.fluid} alt="Gransarto" />
            </div>
          </div>
        </div>
      </section>

      <section style={{ paddingBottom: "90px" }}>
        <p className="has-text-centered mb-6">
          <Link
            to="/tessuto00011"
            className=" button has-text-weight-bold is-dark is-large is-radiusless"
          >
            {lingua.sito.bottone1}
          </Link>
        </p>
      </section>

      <section
        className="section is-medium pasq ss-style-zigzag"
        style={{ backgroundColor: "#eaeae8" }}
      >
        <div className="container is-max-desktop">
          <div className="columns">
            <div className="column">
              <div className="ls-section-content has-text-centered ls-space-bottom">
                <h3 className="title has-text-dark">{lingua.contact.titolo}</h3>
                <p
                  className="content has-text-grey is-size-5"
                  style={{ marginBottom: "3rem" }}
                >
                  {lingua.contact.sottotitolo}
                </p>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column ">
              <form method="post" action="https://formspree.io/f/mlearjjk">
                <input type="text" name="_gotcha" style={{ display: "none" }} />
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded">
                        <label
                          className="screen-reader-text is-large"
                          htmlFor="lsNameInput"
                        >
                          {lingua.contact.nome}
                        </label>
                        <input
                          className="input is-radiusless is-large"
                          id="lsNameInput"
                          type="text"
                          placeholder={lingua.sito.nome}
                          name="nome"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </p>
                    </div>
                    <div className="field">
                      <p className="control is-expanded">
                        <label
                          className="screen-reader-text is-large"
                          htmlFor="lsEmailInput"
                        >
                          {lingua.contact.email}
                        </label>
                        <input
                          className="input is-radiusless is-large"
                          id="lsEmailInput"
                          type="email"
                          placeholder={lingua.sito.email}
                          name="_replyto"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="screen-reader-text is-large">
                          {lingua.contact.messaggio}
                        </label>
                        <textarea
                          className="textarea is-radiusless is-large"
                          placeholder={lingua.sito.messaggio}
                          name="messaggio"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <button className="button ls-button-slim has-text-weight-bold is-dark is-radiusless is-large">
                          {lingua.contact.invia}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    lang: state.shop.lingua,
  };
}

export default connect(mapStateToProps)(IndexPage);

export const query = graphql`
  query {
    sfondo: file(relativePath: { regex: "/sito/sfondo.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hero: file(relativePath: { regex: "/sito/hero.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1340) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shot1: file(relativePath: { regex: "/sito/last/shot1.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 5090) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shot2: file(relativePath: { regex: "/sito/last/shot2.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 5090) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shot3: file(relativePath: { regex: "/sito/last/shot3.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 5090) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shot4: file(relativePath: { regex: "/sito/last/shot4.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 5090) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shot5: file(relativePath: { regex: "/sito/last/shot5.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 5090) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shot6: file(relativePath: { regex: "/sito/last/shot6.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shot7: file(relativePath: { regex: "/sito/last/shot7.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shot8: file(relativePath: { regex: "/sito/last/shot8.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo1: file(relativePath: { regex: "/sito/last/sartorie.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo2: file(relativePath: { regex: "/sito/last/midena.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo3: file(relativePath: { regex: "/sito/last/turrisi.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo4: file(relativePath: { regex: "/sito/last/sumisura.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo5: file(relativePath: { regex: "/sito/last/gransarto.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    camicia1: file(relativePath: { regex: "/sito/camicia1.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    camicia2: file(relativePath: { regex: "/sito/camicia2.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    camicia3: file(relativePath: { regex: "/sito/camicia3.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    camicia4: file(relativePath: { regex: "/sito/camicia4.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    camicia5: file(relativePath: { regex: "/sito/camicia5.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    camicia6: file(relativePath: { regex: "/sito/camicia6.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Prodotti: allAirtableProdotti(
      filter: { table: { eq: "prodotti" } }
      limit: 4
    ) {
      totalCount
      edges {
        node {
          data {
            ID
            Nome_it
            Nome_en
            Nome_es
            Nome_fr
            slug
            PrezzoOfferta
            PrezzoListino
            Immagine {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
