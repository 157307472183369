import React from "react"
import { connect } from "react-redux"
import { Link } from "gatsby"

import { lingua_it } from "../i18n/gdpr-it-IT"
import { lingua_en } from "../i18n/gdpr-en-GB"
import { lingua_es } from "../i18n/gdpr-es-ES"
import { lingua_fr } from "../i18n/gdpr-fr-FR"

import { owner } from "../data"

const Terms = props => {
  let lingua = {}

  switch (props.lang) {
    case "it-IT":
      lingua = lingua_it.terms
      break
    case "fr-FR":
      lingua = lingua_fr.terms
      break
    case "es-ES":
      lingua = lingua_es.terms
      break
    case "en-GB":
      lingua = lingua_en
      break
    default:
      lingua = lingua_it.terms
  }

  return (
    <div className="section">
      <div className="container">
        <div className="content">
          <h1 className="title is-spaced is-3">{lingua.titolo}</h1>

          <h2 className="subtitle is-4">{lingua.sottotitolo_01}</h2>
          <p>
            {lingua.testo_01} {owner.appName} ({owner.appUrl}) {lingua.testo_02}
          </p>
          <ol>
            <li>{lingua.testo_03}</li>
            <li>{lingua.testo_04}</li>
            <li>{lingua.testo_05}</li>
            <li>{lingua.testo_06}</li>
            <li>{lingua.testo_07}</li>
            <li>{lingua.testo_08}</li>
            <li>{lingua.testo_09}</li>
            <li>{lingua.testo_10}</li>
            <li>{lingua.testo_11}</li>
            <li>{lingua.testo_12}</li>
            <li>{lingua.testo_13}</li>
            <li>{lingua.testo_14}</li>
            <li>{lingua.testo_14a}</li>
            <li>{lingua.testo_14b}</li>
          </ol>

          <ol>
            <li>
              {lingua.sottotitolo_02}
              <ol>
                <li>
                  {lingua.testo_15} {owner.appUrl}
                </li>
                <li>{lingua.testo_16}</li>
              </ol>
            </li>

            <li>
              {lingua.sottotitolo_03}
              <ol>
                <li>
                  {lingua.testo_17} {owner.azienda}, {lingua.testo_18}{" "}
                  {owner.indirizzo} - {owner.citta}, {lingua.testo_19}{" "}
                  {owner.registroDi} {lingua.testo_20} {owner.piva}{" "}
                  {lingua.testo_21} {owner.azienda} {lingua.testo_22}
                  <br />
                  {lingua.testo_23} {owner.azienda}:
                  <br />- {lingua.testo_24} {owner.email}
                  <br />- {lingua.testo_25} {owner.indirizzo} - {owner.citta}
                </li>
                <li>
                  {lingua.testo_26} {owner.appUrl} {lingua.testo_27}{" "}
                  {owner.appUrl} {lingua.testo_28}
                </li>
                <li>{lingua.testo_29}</li>
                <li>
                  {lingua.testo_30} {owner.appUrl} {lingua.testo_31}{" "}
                  {owner.appUrl} {lingua.testo_32}
                </li>
                <li>{lingua.testo_33}</li>
                <li>{lingua.testo_34}</li>
              </ol>
            </li>

            <li>
              {lingua.sottotitolo_04}
              <ol>
                <li>
                  {lingua.testo_35} {owner.azienda} {lingua.testo_36}
                </li>
                <li>{lingua.testo_37}</li>
                <li>
                  {lingua.testo_38}
                  <br />- {lingua.testo_39}
                  <br />- {lingua.testo_40}
                  <br />- {lingua.testo_41}
                  <br />- {lingua.testo_42}
                  <br />- {lingua.testo_43}
                  <br />- {lingua.testo_44}
                </li>
                <li>{lingua.testo_45}</li>
                <li>{lingua.testo_46}</li>
                <li>{lingua.testo_47}</li>
              </ol>
            </li>

            <li>
              {lingua.sottotitolo_05}
              <ol>
                <li>{lingua.testo_48}</li>
                <li>{lingua.testo_49}</li>
                <li>{lingua.testo_50}</li>
                <li>{lingua.testo_51}</li>
                <li>{lingua.testo_52}</li>
                <li>{lingua.testo_53}</li>
                <li>{lingua.testo_54}</li>
                <li>{lingua.testo_55}</li>
              </ol>
            </li>

            <li>
              {lingua.sottotitolo_06}
              <ol>
                <li>{lingua.testo_56}</li>
                <li>{lingua.testo_57}</li>
                <li>{lingua.testo_58}</li>
              </ol>
            </li>

            <li>
              {lingua.sottotitolo_07}
              <ol>
                <li>
                  {lingua.testo_59} {owner.appUrl} {lingua.testo_60}
                </li>
              </ol>
            </li>

            <li>
              {lingua.sottotitolo_08}
              <ol>
                <li>
                  {lingua.testo_61}
                  <br />- {lingua.testo_62}
                  <br />- {lingua.testo_63}
                  <br />- {lingua.testo_64}
                  <br />- {lingua.testo_65}
                </li>
                <li>{lingua.testo_66}</li>
              </ol>
            </li>

            <li>
              {lingua.sottotitolo_09}
              <ol>
                <li>{lingua.testo_67}</li>
                <li>{lingua.testo_68}</li>
                <li>{lingua.testo_69}</li>
                <li>
                  {lingua.testo_70}
                  <br />- {lingua.testo_71}
                  <br />- {lingua.testo_72}
                  <br />- {lingua.testo_73}
                  <br />- {lingua.testo_74}
                  <br />- {lingua.testo_75}
                  <br />- {lingua.testo_76}
                </li>
                <li>{lingua.testo_77}</li>
                <li>{lingua.testo_78}</li>
                <li>{lingua.testo_79}</li>
                <li>{lingua.testo_80}</li>
                <li>{lingua.testo_81}</li>
                <li>{lingua.testo_82}</li>
                <li>{lingua.testo_83}</li>
              </ol>
            </li>

            <li>
              {lingua.sottotitolo_10}
              <ol>
                <li>{lingua.testo_84}</li>
                <li>
                  {lingua.testo_85}
                  <br />- {lingua.testo_86}
                  <br />- {lingua.testo_87}
                  <br />- {lingua.testo_88}
                </li>
                <li>{lingua.testo_89}</li>
              </ol>
            </li>

            <li>
              {lingua.sottotitolo_11}
              <ol>
                <li>
                  {lingua.testo_90} {owner.foro}
                  {lingua.testo_91} {owner.foro}
                </li>
              </ol>
            </li>

            <li>
              {lingua.sottotitolo_12}
              <ol>
                <li>{lingua.testo_92}</li>
              </ol>
            </li>

            <li>
              {lingua.sottotitolo_13}
              <ol>
                <li>
                  {lingua.testo_93} {owner.appUrl} {lingua.testo_94}
                </li>
              </ol>
            </li>

            <li>
              {lingua.sottotitolo_14}
              <ol>
                <li>
                  {lingua.testo_95}{" "}
                  <Link to={"/privacy-policy/"}>{lingua.testo_96}</Link>
                </li>
              </ol>
            </li>

            <li>
              {lingua.sottotitolo_15}
              <ol>
                <li>
                  {lingua.testo_97} {owner.azienda} {lingua.testo_98}{" "}
                  {owner.email} {lingua.testo_99} {owner.azienda}{" "}
                  {owner.indirizzo} - {owner.citta}.
                </li>
              </ol>
            </li>
          </ol>

          <p>
            <Link to="/" className="uk-button uk-button-default" type="button">
              {lingua.torna}
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    lang: state.shop.lingua,
  }
}

export default connect(mapStateToProps)(Terms)
