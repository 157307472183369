import React from "react"
import { connect } from "react-redux"
import Img from "gatsby-image"
import { Link, graphql } from "gatsby"

import { euro } from "../components/helpers"
import FilterShop from "../components/extra/filterShop"

const Categoria = props => {
  const { numPages } = props.pageContext
  const { langu, data } = props

  return (
    <>
      <div className="section" style={{ background: "#eaeae8" }}>
        <div className="container">
          <Link to="/shop/">
            <h1 className="title is-spaced is-3">Shop</h1>
          </Link>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-3">
              <FilterShop
                cat={data.tutteCategorie}
                lin={langu}
                count={data.conteggio.totalCount}
              />
            </div>
            <div className="column is-9">
              <div className="columns is-multiline">
                {langu &&
                  data.Prodotti.edges.map(item => (
                    <Link
                      to={`/${item.node.data.slug}`}
                      key={item.node.data.ID}
                      className="column is-one-third"
                    >
                      <div className="card is-shadowless">
                        <div className="card-image">
                          {item.node.data.Immagine.localFiles.length ? (
                            <Img
                              fluid={
                                item.node.data.Immagine.localFiles[0]
                                  .childImageSharp.fluid
                              }
                            />
                          ) : null}
                        </div>
                        <div
                          className="card-content"
                          style={{ marginTop: "auto" }}
                        >
                          <p className="is-size-6 has-text-weight-bold">
                            {
                              item.node.data[
                                `Nome_${langu.substring(0, 2).toLowerCase()}`
                              ]
                            }
                          </p>
                          <p
                            style={{ marginBottom: "1rem" }}
                            className="is-size-5 has-text-weight-semibold"
                          >
                            {item.node.data.PrezzoOfferta > 0 && (
                              <s
                                className="is-size-6"
                                style={{ marginRight: "1rem", opacity: "0.5" }}
                              >
                                {euro(item.node.data.PrezzoListino)}
                              </s>
                            )}
                            {item.node.data.PrezzoOfferta > 0
                              ? euro(item.node.data.PrezzoOfferta)
                              : euro(item.node.data.PrezzoListino)}
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
          {numPages > 1 && (
            <nav
              className="pagination is-rounded is-centered"
              role="navigation"
            >
              <ul className="pagination-list">
                {Array.from({ length: numPages }, (_, i) => (
                  <li key={`pagination-number${i + 1}`}>
                    <Link
                      className="pagination-link"
                      activeClassName="is-current"
                      to={`/shop/${i === 0 ? "" : i + 1}/`}
                    >
                      {i + 1}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </div>
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    langu: state.shop.lingua,
  }
}

export default connect(mapStateToProps)(Categoria)

export const shopQuery = graphql`
  query catPageQuery($skip: Int!, $limit: Int!, $slug: String!) {
    Prodotti: allAirtableProdotti(
      filter: { table: { eq: "prodotti" }, data: { Categorie: { eq: $slug } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          data {
            ID
            Nome_it
            Nome_en
            Nome_es
            Nome_fr
            slug
            PrezzoOfferta
            PrezzoListino
            Immagine {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    tutteCategorie: allAirtableCategorie {
      edges {
        node {
          recordId
          data {
            Nome_it
            Nome_en
            Nome_es
            Nome_fr
            id
            prodotti
          }
        }
      }
    }
    conteggio: allAirtableProdotti {
      totalCount
    }
  }
`
