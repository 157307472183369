import React from 'react'
import { connect } from "react-redux";

import { setflaglegenda } from "../../state/actions";

const Legenda = (props) => {

    const { lingua, flag, setFlag } = props
    return (
        <>
            {flag === false &&
                <>
                    <div className="bubble1 has-background-link is-hidden-touch content">
                        <ul>
                            <li><p>{lingua.bubble.seconda}</p></li>
                            <li><p>{lingua.bubble.terza}</p></li>
                            <li><p>{lingua.bubble.quarta}</p></li>
                        </ul>
                        <hr />
                        <button onClick={() => setFlag(true)} className="button is-info is-inverted is-outlined is-small">{lingua.bubble.quinta}</button>
                    </div>

                    <div className="bubble2 has-background-link is-hidden-desktop content is-small">
                        <ul>
                            <li><p>{lingua.bubble.sesta}</p></li>
                            <li><p>{lingua.bubble.settima}</p></li>
                            <li><p>{lingua.bubble.ottava}</p></li>
                        </ul>
                        <hr />
                        <button onClick={() => setFlag(true)} className="button is-info is-inverted is-outlined is-small">{lingua.bubble.quinta}</button>
                    </div>
                </>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        flag: state.shop.flagLegenda,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setFlag: (val) => dispatch(setflaglegenda(val)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Legenda)