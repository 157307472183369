import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

import { connect } from "react-redux";
import {
  setcolore,
  setdisegno,
  setmateriale,
  setbrand,
} from "../../state/actions";

import { FaCircle } from "react-icons/fa";

import { Scrollbars } from "react-custom-scrollbars";

import ElencoTessuti from "./elencotessuti";

const Tessuti = (props) => {
  const tex = useStaticQuery(
    graphql`
      query navTessuti {
        allAirtable(sort: { order: ASC, fields: data___prezzo }) {
          edges {
            node {
              data {
                colore
                colore_dettaglio_it
                colore_dettaglio_en
                colore_dettaglio_fr
                colore_dettaglio_es
                materiale
                disegno
                brand
                prezzo
                prezzo_old
                immagine {
                  localFiles {
                    childImageSharp {
                      fluid(maxWidth: 400, maxHeight: 250) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                _id
                codice
                nome
                Path
              }
            }
          }
        }
      }
    `
  );
  const {
    lingua,
    corrente,
    filtri,
    colore,
    setColore,
    disegno,
    setDisegno,
    materiale,
    setMateriale,
    brand,
    setBrand,
  } = props;

  const allPosts = tex.allAirtable.edges;

  const emptyQuery = "";

  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  });

  const handleInputChange = (event) => {
    const query = event.target.value;

    const posts = tex.allAirtable.edges || [];

    const filteredData = posts.filter((post) => {
      const { codice, nome } = post.node.data;
      return (
        codice.toLowerCase().includes(query.toLowerCase()) ||
        nome.toLowerCase().includes(query.toLowerCase())
      );
    });

    setState({
      query,
      filteredData,
    });
  };

  const perColore = (col) => {
    return colore !== "" ? col.node.data.colore.includes(colore) : posts;
  };
  const perDisegno = (dis) => {
    return disegno !== "" ? dis.node.data.disegno === disegno : posts;
  };
  const perMateriale = (mat) => {
    return materiale !== "" ? mat.node.data.materiale === materiale : posts;
  };
  const perBrand = (bra) => {
    return brand !== "" ? bra.node.data.brand === brand : posts;
  };

  const { filteredData, query } = state;
  const hasSearchResults = filteredData && query !== emptyQuery;
  const posts = hasSearchResults ? filteredData : allPosts;

  const percolore = posts.filter(perColore);
  const perdisegno = percolore.filter(perDisegno);
  const permateriale = perdisegno.filter(perMateriale);
  const filtrato = permateriale.filter(perBrand);

  return (
    <div className="columns is-gapless has-background-white" >
      <div
        className={
          filtri
            ? "column is-narrow container-narrow"
            : "column is-narrow container-narrow is-hidden-mobile"
        }
      >
        <div className="is-hidden-touch is-hidden-desktop-only">
          <Scrollbars
            universal
            autoHide
            style={{ height: "calc(100vh - 3.25rem)", width: "280px" }}
          >
            <aside className="menu section">
              <div className="menu-list">
                <input
                  style={{ marginBottom: "2rem" }}
                  className="input is-rounded is-hidden-mobile"
                  type="text"
                  aria-label="Search"
                  placeholder={lingua.cercaTessuto}
                  onChange={handleInputChange}
                />
              </div>

              <div className="menu-list" style={{ marginBottom: "1rem" }}>
                <p className="menu-label">{lingua.colori}</p>
                <button
                  onClick={() => setColore("")}
                  style={{ marginBottom: "1rem", fontSize: "1rem" }}
                  className={
                    colore === ""
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                >
                  {lingua.tuttiColori}
                </button>
                <div className="panel-colors ">
                  <div className="level is-mobile">
                    <div className="level-left">
                      <button
                        style={{ fontSize: "1rem" }}
                        onClick={() => setColore("Grigio")}
                        className={
                          colore === "Grigio"
                            ? "buttonLinkTras has-background-primary level-item"
                            : "buttonLinkTras level-item"
                        }
                      >
                        <span
                          className="color-icons"
                          data-tooltip={lingua.coloriTip.grigio}
                        >
                          <FaCircle style={{ color: "#868686" }} />
                        </span>
                      </button>
                      <button
                        style={{ fontSize: "1rem" }}
                        onClick={() => setColore("Blu")}
                        className={
                          colore === "Blu"
                            ? "buttonLinkTras has-background-primary level-item"
                            : "buttonLinkTras level-item"
                        }
                      >
                        <span
                          className="color-icons"
                          data-tooltip={lingua.coloriTip.blu}
                        >
                          <FaCircle style={{ color: "#5d7e9d" }} />
                        </span>
                      </button>
                      <button
                        style={{ fontSize: "1rem" }}
                        onClick={() => setColore("Nero")}
                        className={
                          colore === "Nero"
                            ? "buttonLinkTras has-background-primary level-item"
                            : "buttonLinkTras level-item"
                        }
                      >
                        <span
                          className="color-icons"
                          data-tooltip={lingua.coloriTip.nero}
                        >
                          <FaCircle style={{ color: "#222222" }} />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="panel-colors ">
                  <div className="level is-mobile">
                    <div className="level-left">
                      <button
                        style={{ fontSize: "1rem" }}
                        onClick={() => setColore("Marrone")}
                        className={
                          colore === "Marrone"
                            ? "buttonLinkTras has-background-primary level-item"
                            : "buttonLinkTras level-item"
                        }
                      >
                        <span
                          className="color-icons"
                          data-tooltip={lingua.coloriTip.marrone}
                        >
                          <FaCircle style={{ color: "#6e5c4d" }} />
                        </span>
                      </button>
                      <button
                        style={{ fontSize: "1rem" }}
                        onClick={() => setColore("Verde")}
                        className={
                          colore === "Verde"
                            ? "buttonLinkTras has-background-primary level-item"
                            : "buttonLinkTras level-item"
                        }
                      >
                        <span
                          className="color-icons"
                          data-tooltip={lingua.coloriTip.verde}
                        >
                          <FaCircle style={{ color: "#7fc07f" }} />
                        </span>
                      </button>
                      <button
                        style={{ fontSize: "1rem" }}
                        onClick={() => setColore("Bianco")}
                        className={
                          colore === "Bianco"
                            ? "buttonLinkTras has-background-primary level-item"
                            : "buttonLinkTras level-item"
                        }
                      >
                        <span
                          className="color-icons"
                          data-tooltip={lingua.coloriTip.bianco}
                        >
                          <FaCircle style={{ color: "#f2f2f2" }} />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="panel-colors ">
                  <div className="level is-mobile">
                    <div className="level-left">
                      <button
                        style={{ fontSize: "1rem" }}
                        onClick={() => setColore("Viola")}
                        className={
                          colore === "Viola"
                            ? "buttonLinkTras has-background-primary level-item"
                            : "buttonLinkTras level-item"
                        }
                      >
                        <span
                          className="color-icons"
                          data-tooltip={lingua.coloriTip.viola}
                        >
                          <FaCircle style={{ color: "#a077b3" }} />
                        </span>
                      </button>
                      <button
                        style={{ fontSize: "1rem" }}
                        onClick={() => setColore("Arancio")}
                        className={
                          colore === "Arancio"
                            ? "buttonLinkTras has-background-primary level-item"
                            : "buttonLinkTras level-item"
                        }
                      >
                        <span
                          className="color-icons"
                          data-tooltip={lingua.coloriTip.arancione}
                        >
                          <FaCircle style={{ color: "#f0885d" }} />
                        </span>
                      </button>
                      <button
                        style={{ fontSize: "1rem" }}
                        onClick={() => setColore("Beige")}
                        className={
                          colore === "Beige"
                            ? "buttonLinkTras has-background-primary level-item"
                            : "buttonLinkTras level-item"
                        }
                      >
                        <span
                          className="color-icons"
                          data-tooltip={lingua.coloriTip.beige}
                        >
                          <FaCircle style={{ color: "#fce8cd" }} />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="panel-colors ">
                  <div className="level is-mobile">
                    <div className="level-left">
                      <button
                        style={{ fontSize: "1rem" }}
                        onClick={() => setColore("Giallo")}
                        className={
                          colore === "Giallo"
                            ? "buttonLinkTras has-background-primary level-item"
                            : "buttonLinkTras level-item"
                        }
                      >
                        <span
                          className="color-icons"
                          data-tooltip={lingua.coloriTip.giallo}
                        >
                          <FaCircle style={{ color: "#f7d764" }} />
                        </span>
                      </button>
                      <button
                        style={{ fontSize: "1rem" }}
                        onClick={() => setColore("Rosa")}
                        className={
                          colore === "Rosa"
                            ? "buttonLinkTras has-background-primary level-item"
                            : "buttonLinkTras level-item"
                        }
                      >
                        <span
                          className="color-icons"
                          data-tooltip={lingua.coloriTip.rosa}
                        >
                          <FaCircle style={{ color: "#ecb9d7" }} />
                        </span>
                      </button>
                      <button
                        style={{ fontSize: "1rem" }}
                        onClick={() => setColore("Rosso")}
                        className={
                          colore === "Rosso"
                            ? "buttonLinkTras has-background-primary level-item"
                            : "buttonLinkTras level-item"
                        }
                      >
                        <span
                          className="color-icons"
                          data-tooltip={lingua.coloriTip.rosso}
                        >
                          <FaCircle style={{ color: "#d25b5c" }} />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="menu-list" style={{ marginBottom: "1rem" }}>
                <p className="menu-label">{lingua.disegni}</p>
                <button
                  style={{ fontSize: "1rem" }}
                  onClick={() => setDisegno("")}
                  className={
                    disegno === ""
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                >
                  {lingua.tuttiDisegni}
                </button>
                <button
                  style={{ fontSize: "1rem" }}
                  onClick={() => setDisegno("Tinta unita")}
                  className={
                    disegno === "Tinta unita"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                >
                  {lingua.tintaUnita}
                </button>
                <button
                  style={{ fontSize: "1rem" }}
                  onClick={() => setDisegno("Righe")}
                  className={
                    disegno === "Righe"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                >
                  {lingua.righe}
                </button>
                <button
                  style={{ fontSize: "1rem" }}
                  onClick={() => setDisegno("Quadretti")}
                  className={
                    disegno === "Quadretti"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                >
                  {lingua.quadretti}
                </button>
                <button
                  style={{ fontSize: "1rem" }}
                  onClick={() => setDisegno("Fantasia")}
                  className={
                    disegno === "Fantasia"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                >
                  {lingua.fantasia}
                </button>
              </div>

              <div className="menu-list" style={{ marginBottom: "1rem" }}>
                <p className="menu-label">{lingua.materiali}</p>
                <button
                  style={{ fontSize: "1rem" }}
                  onClick={() => setMateriale("")}
                  className={
                    materiale === ""
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                >
                  {lingua.tuttiMateriali}
                </button>
                <button
                  style={{ fontSize: "1rem" }}
                  onClick={() => setMateriale("Cotone")}
                  className={
                    materiale === "Cotone"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                >
                  {lingua.cotone}
                </button>
                <button
                  style={{ fontSize: "1rem" }}
                  onClick={() => setMateriale("Lino")}
                  className={
                    materiale === "Lino"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                >
                  {lingua.lino}
                </button>
              </div>

              <div className="menu-list" style={{ marginBottom: "1rem" }}>
                <p className="menu-label">{lingua.brands}</p>
                <button
                  style={{ fontSize: "1rem" }}
                  onClick={() => setBrand("")}
                  className={
                    brand === ""
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                >
                  {lingua.tuttiBrands}
                </button>
                <button
                  style={{ fontSize: "1rem" }}
                  onClick={() => setBrand("Canclini")}
                  className={
                    brand === "Canclini"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                >
                  Canclini
                </button>
                <button
                  style={{ fontSize: "1rem" }}
                  onClick={() => setBrand("Mileta")}
                  className={
                    brand === "Mileta"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                >
                  Mileta
                </button>
                <button
                  style={{ fontSize: "1rem" }}
                  onClick={() => setBrand("Texta")}
                  className={
                    brand === "Texta"
                      ? "buttonLink panel-block has-text-primary"
                      : "buttonLink panel-block"
                  }
                >
                  Texta
                </button>
              </div>
            </aside>
          </Scrollbars>
        </div>

        <div className="is-hidden-widescreen">
          <aside className="menu section">
            <div className="menu-list">
              <input
                style={{ marginBottom: "2rem" }}
                className="input is-rounded is-hidden-mobile"
                type="text"
                aria-label="Search"
                placeholder="Cerca un tessuto..."
                onChange={handleInputChange}
              />
            </div>

            <div className="menu-list" style={{ marginBottom: "1rem" }}>
              <p className="menu-label">{lingua.colori}</p>
              <button
                onClick={() => setColore("")}
                style={{ marginBottom: "1rem", fontSize: "1rem" }}
                className={
                  colore === ""
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
              >
                {lingua.tuttiColori}
              </button>
              <div className="panel-colors ">
                <div className="level is-mobile">
                  <div className="level-left">
                    <button
                      style={{ fontSize: "1rem" }}
                      onClick={() => setColore("Grigio")}
                      className={
                        colore === "Grigio"
                          ? "buttonLinkTras has-background-primary level-item"
                          : "buttonLinkTras level-item"
                      }
                    >
                      <span
                        className="color-icons"
                        data-tooltip={lingua.coloriTip.grigio}
                      >
                        <FaCircle style={{ color: "#868686" }} />
                      </span>
                    </button>
                    <button
                      style={{ fontSize: "1rem" }}
                      onClick={() => setColore("Blu")}
                      className={
                        colore === "Blu"
                          ? "buttonLinkTras has-background-primary level-item"
                          : "buttonLinkTras level-item"
                      }
                    >
                      <span
                        className="color-icons"
                        data-tooltip={lingua.coloriTip.blu}
                      >
                        <FaCircle style={{ color: "#5d7e9d" }} />
                      </span>
                    </button>
                    <button
                      style={{ fontSize: "1rem" }}
                      onClick={() => setColore("Nero")}
                      className={
                        colore === "Nero"
                          ? "buttonLinkTras has-background-primary level-item"
                          : "buttonLinkTras level-item"
                      }
                    >
                      <span
                        className="color-icons"
                        data-tooltip={lingua.coloriTip.nero}
                      >
                        <FaCircle style={{ color: "#222222" }} />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="panel-colors ">
                <div className="level is-mobile">
                  <div className="level-left">
                    <button
                      style={{ fontSize: "1rem" }}
                      onClick={() => setColore("Marrone")}
                      className={
                        colore === "Marrone"
                          ? "buttonLinkTras has-background-primary level-item"
                          : "buttonLinkTras level-item"
                      }
                    >
                      <span
                        className="color-icons"
                        data-tooltip={lingua.coloriTip.marrone}
                      >
                        <FaCircle style={{ color: "#6e5c4d" }} />
                      </span>
                    </button>
                    <button
                      style={{ fontSize: "1rem" }}
                      onClick={() => setColore("Verde")}
                      className={
                        colore === "Verde"
                          ? "buttonLinkTras has-background-primary level-item"
                          : "buttonLinkTras level-item"
                      }
                    >
                      <span
                        className="color-icons"
                        data-tooltip={lingua.coloriTip.verde}
                      >
                        <FaCircle style={{ color: "#7fc07f" }} />
                      </span>
                    </button>
                    <button
                      style={{ fontSize: "1rem" }}
                      onClick={() => setColore("Bianco")}
                      className={
                        colore === "Bianco"
                          ? "buttonLinkTras has-background-primary level-item"
                          : "buttonLinkTras level-item"
                      }
                    >
                      <span
                        className="color-icons"
                        data-tooltip={lingua.coloriTip.bianco}
                      >
                        <FaCircle style={{ color: "#f2f2f2" }} />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="panel-colors ">
                <div className="level is-mobile">
                  <div className="level-left">
                    <button
                      style={{ fontSize: "1rem" }}
                      onClick={() => setColore("Viola")}
                      className={
                        colore === "Viola"
                          ? "buttonLinkTras has-background-primary level-item"
                          : "buttonLinkTras level-item"
                      }
                    >
                      <span
                        className="color-icons"
                        data-tooltip={lingua.coloriTip.viola}
                      >
                        <FaCircle style={{ color: "#a077b3" }} />
                      </span>
                    </button>
                    <button
                      style={{ fontSize: "1rem" }}
                      onClick={() => setColore("Arancio")}
                      className={
                        colore === "Arancio"
                          ? "buttonLinkTras has-background-primary level-item"
                          : "buttonLinkTras level-item"
                      }
                    >
                      <span
                        className="color-icons"
                        data-tooltip={lingua.coloriTip.arancio}
                      >
                        <FaCircle style={{ color: "#f0885d" }} />
                      </span>
                    </button>
                    <button
                      style={{ fontSize: "1rem" }}
                      onClick={() => setColore("Beige")}
                      className={
                        colore === "Beige"
                          ? "buttonLinkTras has-background-primary level-item"
                          : "buttonLinkTras level-item"
                      }
                    >
                      <span
                        className="color-icons"
                        data-tooltip={lingua.coloriTip.beige}
                      >
                        <FaCircle style={{ color: "#fce8cd" }} />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="panel-colors ">
                <div className="level is-mobile">
                  <div className="level-left">
                    <button
                      style={{ fontSize: "1rem" }}
                      onClick={() => setColore("Giallo")}
                      className={
                        colore === "Giallo"
                          ? "buttonLinkTras has-background-primary level-item"
                          : "buttonLinkTras level-item"
                      }
                    >
                      <span
                        className="color-icons"
                        data-tooltip={lingua.coloriTip.giallo}
                      >
                        <FaCircle style={{ color: "#f7d764" }} />
                      </span>
                    </button>
                    <button
                      style={{ fontSize: "1rem" }}
                      onClick={() => setColore("Rosa")}
                      className={
                        colore === "Rosa"
                          ? "buttonLinkTras has-background-primary level-item"
                          : "buttonLinkTras level-item"
                      }
                    >
                      <span
                        className="color-icons"
                        data-tooltip={lingua.coloriTip.rosa}
                      >
                        <FaCircle style={{ color: "#ecb9d7" }} />
                      </span>
                    </button>
                    <button
                      style={{ fontSize: "1rem" }}
                      onClick={() => setColore("Rosso")}
                      className={
                        colore === "Rosso"
                          ? "buttonLinkTras has-background-primary level-item"
                          : "buttonLinkTras level-item"
                      }
                    >
                      <span
                        className="color-icons"
                        data-tooltip={lingua.coloriTip.rosso}
                      >
                        <FaCircle style={{ color: "#d25b5c" }} />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="menu-list" style={{ marginBottom: "1rem" }}>
              <p className="menu-label">{lingua.disegni}</p>
              <button
                style={{ fontSize: "1rem" }}
                onClick={() => setDisegno("")}
                className={
                  disegno === ""
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
              >
                {lingua.tuttiDisegni}
              </button>
              <button
                style={{ fontSize: "1rem" }}
                onClick={() => setDisegno("Tinta unita")}
                className={
                  disegno === "Tinta unita"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
              >
                {lingua.tintaUnita}
              </button>
              <button
                style={{ fontSize: "1rem" }}
                onClick={() => setDisegno("Righe")}
                className={
                  disegno === "Righe"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
              >
                {lingua.righe}
              </button>
              <button
                style={{ fontSize: "1rem" }}
                onClick={() => setDisegno("Quadretti")}
                className={
                  disegno === "Quadretti"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
              >
                {lingua.quadretti}
              </button>
              <button
                style={{ fontSize: "1rem" }}
                onClick={() => setDisegno("Fantasia")}
                className={
                  disegno === "Fantasia"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
              >
                {lingua.fantasia}
              </button>
            </div>

            <div className="menu-list" style={{ marginBottom: "1rem" }}>
              <p className="menu-label">{lingua.materiali}</p>
              <button
                style={{ fontSize: "1rem" }}
                onClick={() => setMateriale("")}
                className={
                  materiale === ""
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
              >
                {lingua.tuttiMateriali}
              </button>
              <button
                style={{ fontSize: "1rem" }}
                onClick={() => setMateriale("Cotone")}
                className={
                  materiale === "Cotone"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
              >
                {lingua.cotone}
              </button>
              <button
                style={{ fontSize: "1rem" }}
                onClick={() => setMateriale("Lino")}
                className={
                  materiale === "Lino"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
              >
                {lingua.lino}
              </button>
            </div>

            <div className="menu-list" style={{ marginBottom: "1rem" }}>
              <p className="menu-label">{lingua.brands}</p>
              <button
                style={{ fontSize: "1rem" }}
                onClick={() => setBrand("")}
                className={
                  brand === ""
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
              >
                {lingua.tuttiBrands}
              </button>
              <button
                style={{ fontSize: "1rem" }}
                onClick={() => setBrand("Canclini")}
                className={
                  brand === "Canclini"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
              >
                Canclini
              </button>
              <button
                style={{ fontSize: "1rem" }}
                onClick={() => setBrand("Mileta")}
                className={
                  brand === "Mileta"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
              >
                Mileta
              </button>
              <button
                style={{ fontSize: "1rem" }}
                onClick={() => setBrand("Texta")}
                className={
                  brand === "Texta"
                    ? "buttonLink panel-block has-text-primary"
                    : "buttonLink panel-block"
                }
              >
                Texta
              </button>
            </div>
          </aside>
        </div>
      </div>

      <div className={filtri ? "column is-hidden-mobile" : "column"}>
        <div className="container-features">
          <ElencoTessuti posts={filtrato} lingua={lingua} corrente={corrente} />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    colore: state.shop.colore,
    disegno: state.shop.disegno,
    materiale: state.shop.materiale,
    brand: state.shop.brand,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setColore: (val) => dispatch(setcolore(val)),
    setDisegno: (val) => dispatch(setdisegno(val)),
    setMateriale: (val) => dispatch(setmateriale(val)),
    setBrand: (val) => dispatch(setbrand(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Tessuti);
