import React from "react";
import { connect } from "react-redux";

import BloccoAcquistoMobile from "../bloccoAcquistoMobile";
import NavFeatures from "../navfeatures";

import NavViste from "../navviste";

import Tred from "./tred";
import Mas from "./mas";

import Legenda from "../legenda";

const Viste = (props) => {
  const { currVista, tessuto, lingua, data, camicia } = props;

  let vista = (
    <Tred
      tessuto={tessuto}
      lingua={lingua}
      data={data}
      camicia={camicia}
      vista={currVista}
    />
  );

  switch (currVista) {
    case "intera":
      vista = (
        <Tred
          tessuto={tessuto}
          lingua={lingua}
          data={data}
          camicia={camicia}
          vista={currVista}
        />
      );
      break;
    case "mask":
      vista = (
        <Mas
          tessuto={tessuto}
          lingua={lingua}
          data={data}
          camicia={camicia}
          vista={currVista}
        />
      );
      break;
    default:
      vista = (
        <Tred
          tessuto={tessuto}
          lingua={lingua}
          data={data}
          camicia={camicia}
          vista={currVista}
        />
      );
  }

  return (
    <>
      <div
        className="column is-7-desktop is-5-widescreen is-7 has-background-grey-paky"
        style={{ overflow: "hidden", position: "relative" }}
      >
        {vista}
        <Legenda lingua={lingua} />
        <NavViste vista={currVista} lingua={lingua} />
        <NavFeatures lingua={lingua} />

        <BloccoAcquistoMobile lingua={lingua} data={data} camicia={camicia} />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    currVista: state.shop.vista,
  };
}

export default connect(mapStateToProps)(Viste);
