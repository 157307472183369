import React from "react";
import { connect } from "react-redux";
import { attivainiziali } from "../../state/actions";

import { Scrollbars } from "react-custom-scrollbars";

import InizialiSettings from "../inizialisettings";

const Iniziali = (props) => {
  const { lingua } = props;

  return (

    <div className="columns is-gapless has-background-white">
      <div className="column ">
        <div className="container-features">
          <Scrollbars autoHide style={{ height: "calc(100vh - 3.25rem)" }}>
            <InizialiSettings lingua={lingua} />
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    attiva: state.shop.camicia.iniziali.attiva,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setAttiva: (val) => dispatch(attivainiziali(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Iniziali);
