export const navColletti = [
  {
    _id: 0,
    nome_it: "Colletto italiano",
    nome_en: "Italian collar",
    nome_fr: "Cou italien",
    nome_es: "Cuello italiano",
    nome_ca: "Colletto italiano",
  },
  {
    _id: 1,
    nome_it: "Colletto italiano semiaperto",
    nome_en: "Classic italian collar",
    nome_fr: "Cou italien classique",
    nome_es: "Cuello italiano clásico",
    nome_ca: "Colletto italiano classico",
  },
  {
    _id: 2,
    nome_it: "Colletto francese",
    nome_en: "French collar",
    nome_fr: "Cou francais",
    nome_es: "Cuello francés clásico",
    nome_ca: "Colletto francese classico",
  },
  {
    _id: 3,
    nome_it: "Colletto francese semiaperto",
    nome_en: "Semi french collar",
    nome_fr: "Cou semi francais",
    nome_es: "Cuello semi-abierto francés",
    nome_ca: "Colletto francese semiaperto",
  },
  {
    _id: 4,
    nome_it: "Colletto button down classico",
    nome_en: "Button down collar",
    nome_fr: "Cou amèricain button down",
    nome_es: "Cuello button down",
    nome_ca: "Colletto botton down classico",
  },
  {
    _id: 5,
    nome_it: "Colletto diplomatico",
    nome_en: "Button down two buttons collar",
    nome_fr: "Cou amèricain button down haute deux boutons",
    nome_es: "Cuello button down alto dos botones",
    nome_ca: "Botton Down Alto due bottoni",
    immagine: "./img/diplomatico.png",
  },
  {
    _id: 6,
    nome_it: "Colletto coreano",
    nome_en: "Smoking collar",
    nome_fr: "Cou cassè",
    nome_es: "Cuello diplomático",
    nome_ca: "Colletto diplomatico",
  },
];

export const navPolsini = [
  {
    _id: 0,
    nome_it: "Polsino arrotondato",
    nome_en: "Rounded cuffs",
    nome_fr: "Poignet ronde",
    nome_es: "Puño redondeado",
    nome_ca: "Polsino arrotondato",
  },
  {
    _id: 1,
    nome_it: "Polsino smussato",
    nome_en: "Beveled cuffs",
    nome_fr: "Poignet carre",
    nome_es: "Puño carre",
    nome_ca: "Polsino smussato",
  },
  {
    _id: 2,
    nome_it: "Polsino dritto",
    nome_en: "Straight cuff",
    nome_fr: "Manchette droite",
    nome_es: "Puño recto",
    nome_ca: "Polsino dritto",
  },
  {
    _id: 3,
    nome_it: "Polsino gemelli",
    nome_en: "Cufflinks cuffs",
    nome_fr: "Poignet manchette",
    nome_es: "Puño biselado",
    nome_ca: "Polsino gemelli",
  },
  {
    _id: 4,
    nome_it: "Manica corta",
    nome_en: "Short sleeve",
    nome_fr: "Manche courte",
    nome_es: "Manga corta",
    nome_ca: "Manica corta",
  },
];

export const navChiusure = [
  {
    _id: 0,
    nome_it: "Chiusura francese",
    nome_en: "French closing",
    nome_fr: "Fermeture simple",
    nome_es: "Cierre francés",
    nome_ca: "Francese",
  },
  {
    _id: 1,
    nome_it: "Chiusura standard",
    nome_en: "Standard closing",
    nome_fr: "Fermeture americaine",
    nome_es: "Cierre estándar",
    nome_ca: "Standard",
  },
  {
    _id: 2,
    nome_it: "Chiusura nascosta",
    nome_en: "Hidden closing",
    nome_fr: "Fermeture cachè",
    nome_es: "Cierre oculto",
    nome_ca: "Nascosta",
  },
];

export const navTaschini = [
  {
    _id: 0,
    nome_it: "Nessun taschino",
    nome_en: "No pocket",
    nome_fr: "Sans poches",
    nome_es: "No bolsillo",
    nome_ca: "Nessun taschino",
  },
  {
    _id: 1,
    nome_it: "Taschino arrotondato",
    nome_en: "Rounded pocket",
    nome_fr: "Poche arrondie",
    nome_es: "Bolsillo redondeado",
    nome_ca: "Taschino arrotondato",
  },
  {
    _id: 2,
    nome_it: "Taschino smussato",
    nome_en: "Beveled pocket",
    nome_fr: "Poche carre",
    nome_es: "Bolsillo biselado",
    nome_ca: "Taschino smussato",
  },
  {
    _id: 3,
    nome_it: "Due taschini arrotondati",
    nome_en: "Two rounded pockets",
    nome_fr: "Deux poches arrondie",
    nome_es: "Dos bolsillos redondeados",
    nome_ca: "Due taschini arrotondati",
  },
  {
    _id: 4,
    nome_it: "Due taschini smussati",
    nome_en: "Two beveled pockets",
    nome_fr: "Deux poches carre",
    nome_es: "Dos bolsillos biselados",
    nome_ca: "Due taschini smussati",
  },
];

export const navPinces = [
  {
    _id: 0,
    nome_it: "Dietro liscio",
    nome_en: "No pinces",
    nome_fr: "Sans pinces",
    nome_es: "Detrás liso",
    nome_ca: "Dietro Liscio",
  },
  {
    _id: 1,
    nome_it: "Dietro con pince centrale",
    nome_en: "One pince",
    nome_fr: "Pinces au centre",
    nome_es: "Detrás con pinza central",
    nome_ca: "Dietro con doppia piega",
  },
  {
    _id: 2,
    nome_it: "Dietro con doppie pinces",
    nome_en: "Double pinces",
    nome_fr: "Double pinces",
    nome_es: "Detrás con pinzas dobles",
    nome_ca: "Dietro con doppie pinces",
  },
  {
    _id: 3,
    nome_it: "Dietro con riprese",
    nome_en: "With shots",
    nome_fr: "Derrière avec des coups",
    nome_es: "Detrás con tiros",
    nome_ca: "Darrere amb trets",
  },
];

export const navBottoni = [
  {
    _id: 0,
    nome_it: "Bottone Bianco",
    nome_en: "White buttons",
    nome_fr: "Boutons blanc",
    nome_es: "Botons blancos",
    nome_ca: "Bottone Bianco",
  },
  {
    _id: 1,
    nome_it: "Bottone Blu",
    nome_en: "Blue buttons",
    nome_fr: "Boutons bleu",
    nome_es: "Botons azules",
    nome_ca: "Bottone Blu",
  },
  {
    _id: 2,
    nome_it: "Bottone Celeste",
    nome_en: "Light blue buttons",
    nome_fr: "Boutons bleu clair",
    nome_es: "Botons celestes",
    nome_ca: "Bottone Celeste",
  },
  {
    _id: 3,
    nome_it: "Bottone Grigio",
    nome_en: "Grey buttons",
    nome_fr: "Boutons gris",
    nome_es: "Botons grises",
    nome_ca: "Bottone Grigio",
  },
  {
    _id: 4,
    nome_it: "Bottone Nero",
    nome_en: "Black buttons",
    nome_fr: "Boutons noir",
    nome_es: "Botons negros",
    nome_ca: "Bottone Nero",
  },
  {
    _id: 5,
    nome_it: "Bottone Rosso",
    nome_en: "Red buttons",
    nome_fr: "Boutons rouge",
    nome_es: "Botons rojos",
    nome_ca: "Bottone Rosso",
  },
  {
    _id: 6,
    nome_it: "Bottone Arancione",
    nome_en: "Orange buttons",
    nome_fr: "Boutons orange",
    nome_es: "Botons naranja",
    nome_ca: "Bottone arancione",
  },
  {
    _id: 7,
    nome_it: "Bottone Verde",
    nome_en: "Green buttons",
    nome_fr: "Boutons vert",
    nome_es: "Botons verde",
    nome_ca: "Bottone verde",
  },
  {
    _id: 8,
    nome_it: "Bottone Rosa",
    nome_en: "Pink buttons",
    nome_fr: "Boutons rose",
    nome_es: "Botons rosado",
    nome_ca: "Bottone Rosa",
  },
];

export const navAsole = [
  {
    _id: -1,
    nome_it: "Asola colore camicia",
    nome_en: "Asola colore camicia",
    nome_es: "Asola colore camicia",
    nome_fr: "Asola colore camicia",
    nome_ca: "Asola colore camicia",
  },
  {
    _id: 0,
    nome_it: "Asola Bianca",
    nome_en: "White buttonholes",
    nome_es: "Ojal blanco",
    nome_fr: "Boutonnière blanc",
    nome_ca: "Asola Bianca",
  },
  {
    _id: 1,
    nome_it: "Asola Blu",
    nome_en: "Blue buttonholes",
    nome_fr: "Boutonnière bleu",
    nome_es: "Ojal azul",
    nome_ca: "Asola Blu",
  },
  {
    _id: 2,
    nome_it: "Asola Celeste",
    nome_en: "Light blue buttonholes",
    nome_fr: "Boutonnière bleu clair",
    nome_es: "Ojal celeste",
    nome_ca: "Asola Celeste",
  },
  {
    _id: 3,
    nome_it: "Asola Grigia",
    nome_en: "Grey buttonholes",
    nome_fr: "Boutonnière gris",
    nome_es: "Ojal gris",
    nome_ca: "Asola Grigia",
  },
  {
    _id: 4,
    nome_it: "Asola Nera",
    nome_en: "Black buttonholes",
    nome_fr: "Boutonnière noir",
    nome_es: "Ojal negro",
    nome_ca: "Asola Nera",
  },
  {
    _id: 5,
    nome_it: "Asola Rossa",
    nome_en: "Red Buttonholes",
    nome_fr: "Boutonnière rouge",
    nome_es: "Ojal rojo",
    nome_ca: "Asola Rossa",
  },
  {
    _id: 6,
    nome_it: "Asola Arancione",
    nome_en: "Orange Buttonholes",
    nome_fr: "Boutonnière orange",
    nome_es: "Ojal naranja",
    nome_ca: "Asola arancione",
  },
  {
    _id: 7,
    nome_it: "Asola Verde",
    nome_en: "Green Buttonholes",
    nome_fr: "Boutonnière vert",
    nome_es: "Ojal verde",
    nome_ca: "Asola verde",
  },
  {
    _id: 8,
    nome_it: "Asola Rosa",
    nome_en: "Pink Buttonholes",
    nome_fr: "Boutonnière rose",
    nome_es: "Ojal rosada",
    nome_ca: "Asola Rosa",
  },
];

export const navFili = [
  {
    _id: 0,
    nome_it: "Filo bianco",
    nome_en: "White thread",
    nome_fr: "Fil blanc",
    nome_es: "Hilo blanco",
    nome_ca: "Filo bianco",
  },
  {
    _id: 1,
    nome_it: "Filo blu",
    nome_en: "Blu thread",
    nome_fr: "Fil bleu",
    nome_es: "Hilo azul",
    nome_ca: "Filo Blu",
  },
  {
    _id: 2,
    nome_it: "Filo celeste",
    nome_en: "Light blue thread",
    nome_fr: "Fil bleu clair",
    nome_es: "Hilo celeste",
    nome_ca: "Filo Celeste",
  },
  {
    _id: 3,
    nome_it: "Filo grigio",
    nome_en: "Grey thread",
    nome_fr: "Fil gris",
    nome_es: "Hilo gris",
    nome_ca: "Filo Grigio",
  },
  {
    _id: 4,
    nome_it: "Filo nero",
    nome_en: "Black thread",
    nome_fr: "Fil noir",
    nome_es: "Hilo negro",
    nome_ca: "Filo Nero",
  },
  {
    _id: 5,
    nome_it: "Filo rosso",
    nome_en: "Red thread",
    nome_fr: "Fil rouge",
    nome_es: "Hilo rojo",
    nome_ca: "Filo rosso",
  },
];

export const navContrasti = [
  {
    _id: 0,
    nome_it: "Nessun contrasto",
    nome_en: "No contrast",
    nome_fr: "No contraste",
    nome_es: "No contraste",
    nome_ca: "Nessun Contrasto",
  },
  {
    _id: 1,
    nome_it: "Hutch 2441S-W21220-0007",
    nome_en: "Hutch 2441S-W21220-0007",
    nome_fr: "Hutch 2441S-W21220-0007",
    nome_es: "Hutch 2441S-W21220-0007",
    nome_ca: "Hutch 2441S-W21220-0007",
  },
  {
    _id: 2,
    nome_it: "Rock 2317H-W22999-0001",
    nome_en: "Rock 2317H-W22999-0001",
    nome_fr: "Rock 2317H-W22999-0001",
    nome_es: "Rock 2317H-W22999-0001",
    nome_ca: "Rock 2317H-W22999-0001",
  },
  {
    _id: 3,
    nome_it: "Rock 2317H-W22993-0001",
    nome_en: "Rock 2317H-W22993-0001",
    nome_fr: "Rock 2317H-W22993-0001",
    nome_es: "Rock 2317H-W22993-0001",
    nome_ca: "Rock 2317H-W22993-0001",
  },
];
