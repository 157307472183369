export const lingua_en = {
  lang: "en",
  title: "3D SHIRT - Customized shirts 3d configurator",
  description: "Customized shirts 3d configurator",
  personalizza: "Customization",
  misure: "Measurements",
  tessuto: "Fabric",
  filtri: "Filters",
  scegliTessuto: "Select the fabric",
  cercaTessuto: "Search fabric...",
  inOfferta: "On sale",
  nonDisponibile: "Not available",
  colore: "Color",
  colori: "Colors",
  tuttiColori: "All colors",
  bianco: "White",
  celeste: "Light blue",
  altriColori: "Other colors",
  disegno: "Drawing",
  disegni: "Drawings",
  tuttiDisegni: "All drawings",
  tintaUnita: "Solid color",
  righe: "Lines",
  quadretti: "Squares",
  fantasia: "Printed",
  materiale: "Fabric",
  materiali: "Fabrics",
  tuttiMateriali: "All fabrics",
  cotone: "Cotton",
  lino: "Linen",
  brand: "Brand",
  brands: "Brands",
  tuttiBrands: "All brands",
  codice: "Code",
  ordina: "Sort by price",
  basso: "Low to high",
  alto: "High to low",
  lato: "Side view",
  front: "Front view",
  retro: "Back view",
  piegata: "Folded view",
  extra: {
    tutti: "All products",
    selTaglia: "Select size",
    desc: "Description",
    correlati: "Related products",
  },
  bubble: {
    prima: "Clicca qui per tornare alla vista camicia.",
    seconda: "Trascina con il tasto SINISTRO del mouse per ruotare la camicia",
    terza: "Trascina con il tasto DESTRO del mouse per spostare la camicia.",
    quarta: "Muovi la ROTELLINA del mouse per lo zoom.",
    quinta: "OK, CHIUDI",
    sesta: "Trascina con un dito per ruotare la camicia.",
    settima: "Trascina con due dita per spostare la camicia.",
    ottava: "Allarga o stringi con due dita per lo zoom.",
  },
  banner: {
    titolo: "Use of Cookies",
    testo1:
      "In this application, we use cookies (or similar technologies) to store information on your computer or mobile device in order to improve your online experience. Cookies are small text files that allow you to navigate efficiently, remember your preferences, help us understand the performance of the application and generally improve your user experience.",
    testo2:
      "By closing this message you consent to the use of cookies based on your (possible) preferences.",
    gestione: "Cookies management",
    acconsento: "I agree",
  },
  sito: {
    titolo: "3D configurator for tailored shirts",
    sottotitolo:
      "3D SHIRT is a 3D configurator for tailored shirts with a simple, immediate and engaging interface, able to offer a realistic perception of the customized product.",
    bottone1: "Try our configurator",
    titolo2: "A 3D configurator that has no limits",
    sottotitolo2:
      "3D SHIRT allows an easy, fast and engaging configuration experience.",
    configura: "Configure",
    titolo3: "Why use the 3D configurator",
    sottotitolo3:
      "There are numerous advantages, for the end user and for the business.",
    titolo4: "Image quality",
    sottotitolo4:
      "Realistic, flawless images are created with the use of powerful 3D software for fashion design.",
    titolo5: "Extreme usability",
    sottotitolo5:
      "It takes on the concept of gamification, that is, it leverages interactivity and fun to achieve its goal.",
    titolo6: "Uniqueness of the product",
    sottotitolo6:
      "By customizing his product, the user has the feeling of buying something special and unique, made especially for him.",
    titolo7: "Response speed",
    sottotitolo7:
      "The configurator uses cutting-edge technologies for app development, which allow real-time responses to each user action.",
    titolo8: "Customization",
    sottotitolo8:
      "There is no limit to the number of items that can be integrated or modified. The configurator is 100% customizable with your own brand and colors.",
    titolo9: " Web Marketing",
    sottotitolo9:
      "By offering an immersive browsing experience, the configurator helps to increase the brand's sales and awareness.",
    titolo10: "Main features of the 3D SHIRT configurator",
    sottotitolo10:
      "The application guides the user from product customization to purchase.",
    titolo11: "Shirt customization",
    sottotitolo11:
      "Through a few simple steps, the shirt is configured in all its aspects, from the choice of fabric and other aspects of style, up to the insertion of the measures.",
    titolo12: "Purchase Process",
    sottotitolo12:
      "The application is able to manage all stages of the purchase process, from the management of the cart, products and discount codes, to the management of shipping and payment.",
    titolo13: "Account creation",
    sottotitolo13:
      "The application provides the ability to create a personal user profile that will allow the user to store his / her measurements and purchases.",
    newshop:
      "Now 3D SHIRT allows the sale of all the products you exhibit in your tailor shop! Not only customized shirts but also ready-made shirts, ties, accessories or whatever you want!",
    last01: "Legal documentation",
    last02:
      "3D SHIRT includes a complete legal documentation, which can be modified according to your needs.",
    last03: "Multilingual",
    last04:
      "With 3D SHIRT your company  is projected on the international market. The app is already translated in 4 languages: Italian, English, French and Spanish. Other languages can be integrated on request.",
    last05: "A complete e-commerce",
    last06:
      "3D SHIRT allows you to present and sell all your products online, thanks to a useful and complete shop section.",
    last07: "",
    last08:
      "3D SHIRT is the meeting point between information technologies and the rich and complex tradition of Artisan Tailoring. A project born in synergy with expert professionals, to respond fully and effectively to concrete needs. Tailors of established traditions and new, dynamic enterprises, use 3Dshirt to enhance and consolidate their business.",
  },
  contact: {
    titolo: "Send us a message",
    sottotitolo:
      "Contact us to request a quote or simply for questions or suggestions. We will get back to you as soon as possible.",
    nome: "Your name",
    telefono: "Your telephone number",
    email: "Your email",
    messaggio: "Leave a message",
    invia: "Send the message",
  },
  header: {
    account: "Account",
    modificapassword: "Change your Password",
    logout: "Logout",
    wishlist: "Wishlist",
    shopping: "Shopping Bag",
    loggedOut: "You are no longer logged in.",
    loggedOutError: "An error has occurred:",
    cambiaPasswordSuccess:
      "We will send you an email containing a unique link to reset your password.",
    cambiaPasswordError: "The following error has occurred:",
  },
  coloriTip: {
    grigio: "Grey",
    blu: "Blue",
    nero: "Black",
    marrone: "Brown",
    verde: "Green",
    bianco: "White",
    viola: "Violet",
    arancione: "Orange",
    beige: "Beige",
    giallo: "Yellow",
    rosa: "Pink",
    rosso: "Red",
  },
  footer: {
    account: "Account",
    login: "Login",
    logout: "Logout",
    cookie: "Cookie Policy",
    privacy: "Privacy Policy",
    termini: "Terms and Conditions",
    recesso: "Right of Withdrawal",
    contattaci: "Contact Us",
    partita: "P.IVA",
  },
  dettaglio: {
    descrizione: "Fabric description",
    comeMisure: "How to take measurements",
    spedizione: "Shipping and return",
  },
  navigazione: {
    lang: "en",
    tessuto: "Fabric",
    scegliTessuto: "Select the fabric",
    inOfferta: "On sale",
    nonDisponibile: "Not available",
    personalizza: "Customize",
    personalizzazione: "Customization",
    polsinoGemelli: "Cufflinks cuff",
    contrastoColletto: "Apply to collar",
    contrastoPolsino: "Apply to cuffs",
    contrastoChiusura: "Apply to button placket",
    chiusura: "Closing",
    colletto: "Collar",
    polsino: "Cuffs",
    taschino: "Pocket",
    pinces: "Pinces",
    asole: "Buttonholes",
    bottoni: "Buttons",
    fili: "Thread",
    contrasto: "Contrasts",
    loading: "Loading...",
    mascherina: "Mask",
    maniche: "Sleeves",
    notfound:
      "There are no fabrics that match your selections. Please choose different filters.",
    iniziali: {
      iniziali: "Monogram",
      inserisciIniziali: "Insert your monogram",
      tueIniziali: "Enter your monogram",
      stile: "Monogram style",
      colore: "Monogram color",
      bianco: "White",
      blu: "Blue",
      celeste: "Light blue",
      grigio: "Grey",
      nero: "Black",
      rosso: "Red",
      verde: "Green",
      arancione: "Orange",
      rosa: "Pink",
      posizione: "Position",
      sopra: "Up",
      centro: "Middle",
      sotto: "Down",
      aggiungiIniziali: "Add initials",
      stile1: "style",
      colore1: "color",
      posizione1: "position",
    },
    misure: {
      metodoTitolo: "Select the mode you prefer",
      metodoTaglia: "Measures from standard sizes",
      metodoCorpo: "Measure your body",
      metodoCamicia: "Measure a shirt that suits you",
      metodoInvia: "Send us your shirt to measure it",
      metodoModifica: "Modification",
      metodoInformazioni: "Informations",
      selezionaTaglia: "Select your size",
      selezionaVestibilita: "Select your fit...",
      misure: "Measurements",
      tueMisure: "Your measurements",
      salva: "Save your measurements",
      errore: "An error has occurred",
      successo: "Measures successfully saved",
      Braccia: "Arms",
      Collo: "Neck",
      Girovita: "Waist",
      Polso: "Wrist",
      Spalle: "Shoulders",
      Torace: "Chest",
      Lunghezza: "Lenght",
      vuoiAiuto: "Would you like some assistance with your measurements?",
      provaSelezionare: "Try selecting your size",
      successoCarica: "Measures successfully loaded.",
      seleziona: "Select...",
      xs: "XS (Neck 38)",
      s: "S (Neck 38)",
      m: "M (Neck 39/40)",
      l: "L (Neck 41/42)",
      xl: "XL (Neck 43/44)",
      xxl: "XXL (Neck 45/46)",
      xxxl: "XXXL (Neck 47/48)",
      oppure: "or",
      stoCaricando: "Loading...",
      carica: "Load your measurements",
      inviaTitolo: "Mail a Shirt to Measure",
      inviaParag1:
        "Want to copy the fit of a shirt you already have, but aren’t sure how to measure it properly?",
      inviaParag2:
        "Mail us your shirt and our experts will measure it and create a special saved size in your account for you. *Note that our measuring causes no damage to the shirt and we will return the shirt to you right away.",
      inviaParag3: "Send to:",
      inviaTitolo2: "When sending, be sure to include:",
      inviaLista1: "The shirt you want us to measure",
      inviaLista2: "Your name",
      inviaLista3: "Phone number",
      inviaLista4: "Email address",
      inviaLista5: "Return shipping address",
      inviaLista6: "Any special instructions",
      infoTipoMisure: "Measure mode:",
      infoTipoMisure0: "Measures from standard sizes",
      infoTipoMisure1: "Measurements taken on your body",
      infoTipoMisure2: "Measurements taken from your shirt",
      infoTipoMisure3: "Will send a shirt to have it measured",
      infoTagliaSelezionata: "Chosen size:",
      infoVestibilita: "Fit",
    },
  },
  barraAcquisto: {
    lang: "en",
    camicia: "Shirt",
    nonDisponibile: "Fabric not available.",
    tooltip: "Add this shirt to cart",
    carrello: "Add to cart",
    tessuto: "Fabric ",
    di: " of ",
    aggiunta: "Shirt added to cart.",
    aggiunta1: "Shirt successfully added to cart.",
    aggiunta2: "What do you want to do now??",
    continua: "Continue your shopping",
    vai: "Go to shopping bag",
    wishlist: {
      lang: "en",
      nuovoPrezzo: "New price",
      nonDisponibile: "Caution. Fabric not available!",
      wishlist: "Wishlist",
      vuota: "Your Wishlist is empty!",
      Camicia: "Shirt",
      tooltip: "Add shirt to wishlist",
      successo: "Shirt succesfully added to wishlist.",
      rimossa: "Shirt succesfully removed from wishlist.",
      Prodotto: "Product",
      Prezzo: "Price",
      Descrizione: "Description",
      Tessuto: "Fabric",
      Disegno: "Drawing",
      Colletto: "Collar",
      Italiano: "Italian",
      "Italiano Classico": "Classic Italian",
      "Francese Classico": "Classic French",
      "Francese Semiaperto": "Semi French",
      "Francese Semiaperto due bottoni": "Semi French two buttons",
      "Botton Down Classico": "Button Down",
      "Botton Down Alto due bottoni": "Button Down two buttons",
      Diplomatico: "Smoking",
      Coreano: "Korean",
      "Polsino Arrotondato": "Rounded Cuff",
      "Polsino Smussato": "Beveled Cuff",
      "Polsino Gemelli": "Cufflinks Cuff",
      "Manica Corta": "Short Sleeve",
      Chiusura: "Closing",
      "Faldoncino Interno non Impunturato": "French",
      "Faldoncino Esterno Impunturato": "Standard",
      "Finta Doppia e bottoni nascosti": "Hidden",
      "Nessun Taschino": "No Pockets",
      "Taschino Arrotondato": "Rounded Pocket",
      "Taschino Smussato": "Bevelled Pocket",
      "Due Taschini Arrotondati": "Two Rounded Pockets",
      "Due Taschini Smussati": "Two Bevelled Pockets",
      "Dietro Liscio": "No Darts",
      "Dietro con Doppie Pinces": "Double Darts",
      "Dietro con Doppia Piega": "Double Folds",
      "Asola Bianca": "White Slot",
      "Asola Blu": "Blue Slot",
      "Asola Celeste": "Light blue Slot",
      "Asola Grigia": "Gray Slot",
      "Asola Nera": "Black Slot",
      "Asola Rossa": "Red Slot",
      "Bottone Bianco": "White Button",
      "Bottone Blu": "Blue Button",
      "Bottone Celeste": "Light blue Button",
      "Bottone Grigio": "Gray Button",
      "Bottone Nero": "Black Button",
      "Bottone Rosso": "Red Button",
      "Filo Bianco": "White Thread",
      "Filo Blu": "Blue Thread",
      "Filo Celeste": "Light blue Thread",
      "Filo Grigio": "Gray Thread",
      "Filo Nero": "Black Thread",
      "Filo Rosso": "Red Thread",
      Contrasto: "Contrast",
      contrastoPolsino: "Cuffs",
      contrastoColletto: "Collar",
      contrastoChiusura: "Closure",
      Stile: "Style",
      Iniziali: "Monogram",
      iniStile: "Style",
      iniColore: "Color",
      iniPosizione: "Position",
      white: "White",
      slateblue: "Blue",
      lightsteelblue: "Light blue",
      lightgray: "Gray",
      black: "Black",
      crimson: "Red",
      posizione: "Position",
      sopra: "Up",
      centro: "Middle",
      sotto: "Down",
      rimuovi: "Remove from Wishlist",
      importa: "Import in the configurator",
      torna: "Back to the configurator",
    },
  },
  tutorial: {
    braccia: {
      header: "Arms",
      titolo: "Arm lenght",
      descrizione:
        "Measure the sleeve from the shoulder to the wrist holding your arm bent at 90°. For accurate measurement, it is necessary to measure from the shoulder to the elbow and proceed to the end of the wrist, where it joins the hand.",
    },
    bracciaCamicia: {
      header: "Arms",
      titolo: "Arm lenght",
      descrizione:
        "Measure the sleeve from seam of the shoulder to the end of the cuff. For accurate measurement, it is necessary to keep the sleeve flat on a flat surface.",
    },
    collo: {
      header: "Neck",
      titolo: "Neck size",
      descrizione:
        "Measure immediately beneath the Adam's apple, sliding the measuring tape around the neck to take the circumference. It is important to insert a finger between the measuring tape and the neck to ensure a comfortable grip.",
    },
    colloCamicia: {
      header: "Neck",
      titolo: "Neck size",
      descrizione:
        "Measure the linear distance between the collar button and the corresponding buttonhole, keeping the collar wide open and lying flat on a flat surface.",
    },
    girovita: {
      header: "Waist",
      titolo: "Waist size",
      descrizione:
        "Measure the waistline at the widest point, at the height of the navel. If you have wide hips it is advisable to take the measurement slightly lower, to measure them in total. For a proper fit, you must adhere to the waistline, always leaving a finger of tolerance between the body and the measuring tape, so that you can sit comfortably. It is important to take the precise measurement, keeping the measuring tape adherent to the body: fit will be given by the style chosen while designigng the shirt.",
    },
    girovitaCamicia: {
      header: "Waist",
      titolo: "Waist size",
      descrizione:
        "Measure the waistline at the widest point at the navel, between the second and third buttons from the bottom, keeping the shirt flat on a flat surface. If you have wide hips, it is advisable to take the measurement slightly lower , to include them in the total measurement.",
    },
    polso: {
      header: "Wrist",
      titolo: "Wrist size",
      descrizione:
        "Measure the wrist circumference at the wrist joint. For a proper fit you have to make the measuring tape adhere to the wrist while retaining a finger of tolerance between the wrist and the measuring tape.",
    },
    polsoCamicia: {
      header: "Wrist",
      titolo: "Wrist size",
      descrizione:
        "Measure the linear distance between the cuff button and the corresponding buttonhole, keeping the cuff open and lying flat on a flat surface.",
    },
    spalle: {
      header: "Shoulders",
      titolo: "Shoulders size",
      descrizione: "Measure the shoulders width at the widest point.",
    },
    spalleCamicia: {
      header: "Shoulders",
      titolo: "Shoulders size",
      descrizione:
        "Measure the shoulder width at the widest point, from the seam of the left arm to the seam of the right arm, keeping the shirt flat on a flat surface.",
    },
    torace: {
      header: "Chest",
      titolo: "Chest size",
      descrizione:
        "Measure the chest circumference at nipple height. For a proper fit, you must adhere the measuring tape to the chest, always holding a finger of tolerance between the body and the measuring tape so that you can move freely.",
    },
    toraceCamicia: {
      header: "Chest",
      titolo: "Chest size",
      descrizione:
        "Measure the circumference of the chest at chest height, between the second and third buttons from above. Measure the linear distance between the button and the opposite corresponding buttonhole.",
    },
    lunghezza: {
      header: "Length",
      titolo: "Length",
      descrizione:
        "Tilt your head forward and measure from the center of the back base of the neck, at the most protruding bone, down to 3/4 of the buttocks for a classic length. If you wear a shirt, measure the back side from the center of the collar seam to the bottom hem.",
    },
    lunghezzaCamicia: {
      header: "Length",
      titolo: "Length",
      descrizione:
        "Keeping the shirt flat on a flat surface, measure from the center of the back base of the neck, at the center of the seam between the shirt and collar, to the bottom hem.",
    },
  },
  contatti: {
    richiesto: "Required field",
    emailInvalida: "Invalid email address",
    titolo: "We love to talk with you",
    successo: "Thank you. Your message has been successfully sent.",
    errore: "Caution. An error has occurred.",
    nome: "Name *",
    email: "Email *",
    oggetto: "Subject *",
    messaggio: "Message *",
    attendere: "Wait...",
    invia: "Send",
    testo01:
      "EVERY ORDER REQUIRES AT LEAST 20 WORKING DAYS BEFORE SHIPPING. WE APOLOGIZE FOR THIS ANNOYANCE BUT YOUR SHIRT WILL NOT BE LIKE ALL THE OTHERS.",
    testo02:
      "Sartoria Turrisi is in Ceglie Messapica, a welcoming town in the Province of Brindisi, in the south of Puglia.",
    testo03:
      "Since 1970 our strong sartorial tradition has been handed down from father to son: we are a small artisan company that operates with passion at every stage of production, which is carried out exclusively within our tailor's shop by highly specialized Italian artisans.",
    testo04:
      'The choice of fabrics and accessories of the highest quality allow us to offer the excellence of the authentic "Made in Italy".',
    testo05:
      "Our goal is to satisfy our client in every aspect, putting our long experience at his disposal.",
    testo06:
      "Today, it is possible to confuse a sculptor with a tailor, because both create shapes.",
    testo07: "KARL KRAUS",
    testo08: "FEEL OUR FABRIC SELECTION WITH YOUR HANDS!",
    testo09: "ORDER YOUR FREE KIT NOW!",
  },
  pagina404: {
    errore: "Error 404",
    nonEsiste: "The page you are looking for does not exist!",
    torna: "Back to configurator",
  },
};
