import React, { useState } from "react";
import { connect } from "react-redux";

import Img from "gatsby-image";
import { graphql } from "gatsby";

import { euro } from "../components/helpers";

import { FiPlus, FiMinus } from "react-icons/fi";

import { lingua_it } from "../i18n/it-IT";
import { lingua_en } from "../i18n/en-GB";
import { lingua_es } from "../i18n/es-ES";
import { lingua_fr } from "../i18n/fr-FR";

import { FiZoomIn } from "react-icons/fi";

import { owner } from "../data";

import ShopDetail from "../components/extra/shopDetail";

import ShopRelated from "../components/extra/shopRelated";

const Prodotto = (props) => {
  const [qty, setQty] = useState(1);
  const [ig, setIg] = useState(0);
  const [attivo, setAttivo] = useState(false);

  const { data, lang } = props;

  const [taglia, setTaglia] = useState(
    data.airtableProdotti.data.Taglia.length > 0
      ? data.airtableProdotti.data.Taglia[0]
      : null
  );

  let lingua = {};

  switch (lang) {
    case "it-IT":
      lingua = lingua_it;
      break;
    case "es-ES":
      lingua = lingua_es;
      break;
    case "fr-FR":
      lingua = lingua_fr;
      break;
    default:
      lingua = lingua_en;
  }

  return (
    <>
      <div className="section">
        <div className="container">
          <div className="columns" style={{ marginBottom: "3rem" }}>
            <div className="column is-1">
              {data.airtableProdotti.data.Immagine.localFiles.map((imag, i) => (
                <button
                  className="is-hidden-mobile"
                  onClick={() => setIg(i)}
                  key={i}
                  style={
                    ig === i
                      ? {
                          width: "100%",
                          marginBottom: "1.5rem",
                          padding: 0,
                          border: "none",
                          background: "transparent",
                          opacity: "0.5",
                        }
                      : {
                          width: "100%",
                          marginBottom: "1.5rem",
                          padding: 0,
                          border: "none",
                          background: "transparent",
                          opacity: "1",
                          cursor: "pointer",
                        }
                  }
                >
                  <Img fluid={imag.childImageSharp.fluid} />
                </button>
              ))}
            </div>
            <div className="column is-6">
              <div style={{ position: "relative" }}>
                <div
                  className="buttons is-hidden-touch is-hidden-desktop-only"
                  style={{
                    position: "absolute",
                    zIndex: 30,
                    left: 0,
                    top: 0,
                  }}
                >
                  <button
                    className="button"
                    style={{ border: "none", background: "transparent" }}
                    onClick={() => setAttivo(true)}
                  >
                    <FiZoomIn style={{ fontSize: "1.5rem" }} />
                  </button>
                </div>
                <Img
                  fluid={
                    data.airtableProdotti.data.Immagine.localFiles[ig]
                      .childImageSharp.fluid
                  }
                  className="is-hidden-mobile"
                />
              </div>
            </div>
            <div className="column is-5">
              <div className="content">
                <p className="title is-spaced is-3">
                  {
                    data.airtableProdotti.data[
                      `Nome_${lang.substring(0, 2).toLowerCase()}`
                    ]
                  }
                </p>
                <p className="subtitle is-6 is-uppercase">
                  SKU: {data.airtableProdotti.data.COD}
                </p>
                <p
                  style={{ marginBottom: "2rem" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      data.airtableProdotti.data[
                        `Breve_${lang.substring(0, 2).toLowerCase()}`
                      ],
                  }}
                />

                <div>
                  <p
                    style={{ marginBottom: "1rem" }}
                    className="is-size-3 has-text-weight-semibold"
                  >
                    {data.airtableProdotti.data.PrezzoOfferta > 0 && (
                      <s
                        className="is-size-4"
                        style={{ marginRight: "1rem", opacity: "0.5" }}
                      >
                        {euro(data.airtableProdotti.data.PrezzoListino)}
                      </s>
                    )}
                    {data.airtableProdotti.data.PrezzoOfferta > 0
                      ? euro(data.airtableProdotti.data.PrezzoOfferta)
                      : euro(data.airtableProdotti.data.PrezzoListino)}
                  </p>

                  <div style={{ marginBottom: "2rem" }} />

                  {data.airtableProdotti.data.Taglia.length > 0 && (
                    <div className="field">
                      <label className="label">{lingua.extra.selTaglia}</label>
                      <div className="control">
                        <div className="select">
                          <select
                            style={{ minWidth: "100px" }}
                            onBlur={(e) => setTaglia(e.target.value)}
                            onChange={(e) => setTaglia(e.target.value)}
                          >
                            {data.airtableProdotti.data.Taglia.map((opt) => (
                              <option key={opt}>{opt}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}

                  <div style={{ marginBottom: "2rem" }} />

                  <div className="columns">
                    <div className="column is-narrow">
                      <div className="field has-addons">
                        <p className="control">
                          <button
                            onClick={() => setQty(qty > 1 ? qty - 1 : qty)}
                            className="button is-radiusless is-light"
                          >
                            <FiMinus />
                          </button>
                        </p>
                        <p className="control">
                          <input
                            type="text"
                            disabled
                            value={qty}
                            className="input is-radiusless is-light"
                            style={{
                              width: "3rem",
                              textAlign: "center",
                              border: "none",
                            }}
                          />
                        </p>
                        <p className="control">
                          <button
                            onClick={() => setQty(qty < 10 ? qty + 1 : qty)}
                            className="button is-radiusless is-light"
                          >
                            <FiPlus />
                          </button>
                        </p>
                      </div>
                    </div>

                    <div className="column">
                      <button
                        className="button is-dark is-radiusless is-fullwidth snipcart-add-item"
                        data-item-id={data.airtableProdotti.data.COD} //{data.codice}
                        data-item-price={
                          data.airtableProdotti.data.PrezzoOfferta > 0
                            ? data.airtableProdotti.data.PrezzoOfferta
                            : data.airtableProdotti.data.PrezzoListino
                        } //{prezzoTotale}
                        data-item-url={
                          owner.appUrl +
                          "/" +
                          data.airtableProdotti.data.slug +
                          "/"
                        }
                        data-item-description={
                          data.airtableProdotti.data[
                            `Breve_${lang.substring(0, 2).toLowerCase()}`
                          ]
                        }
                        data-item-name={
                          data.airtableProdotti.data[
                            `Nome_${lang.substring(0, 2).toLowerCase()}`
                          ]
                        } //{lingua.barraAcquisto.camicia + " " + data.nome}
                        data-item-image={
                          data.airtableProdotti.data.Immagine.localFiles[0]
                            .childImageSharp.fluid.src
                        }
                        data-item-weight={
                          data.airtableProdotti.data.Peso !== ""
                            ? parseInt(data.airtableProdotti.data.Peso)
                            : 0
                        }
                        data-item-quantity={qty}
                        data-item-max-quantity={10}
                        data-item-min-quantity={1}
                        data-item-custom1-name={
                          data.airtableProdotti.data.Taglia.length > 0
                            ? lingua.extra.selTaglia
                            : null
                        }
                        data-item-custom1-options={
                          data.airtableProdotti.data.Taglia.length > 0
                            ? creaTaglia(data.airtableProdotti.data.Taglia)
                            : null
                        }
                        data-item-custom1-value={
                          data.airtableProdotti.data.Taglia.length > 0
                            ? taglia
                            : null
                        }
                      >
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {lingua.barraAcquisto.carrello}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ShopDetail
            descr={
              data.airtableProdotti.data[
                `Descrizione_${lang.substring(0, 2).toLowerCase()}`
              ]
            }
            titolo={lingua.extra.desc}
          />

          {data.airtableProdotti.data.Correlati.length > 0 ? (
            <ShopRelated
              items={data.airtableProdotti.data.Correlati}
              langu={lang}
              titolo={lingua.extra.correlati}
            />
          ) : null}
        </div>
      </div>

      <div
        className={
          attivo
            ? "modal modal-full-screen modal-fx-fadeInScale is-active"
            : "modal modal-full-screen modal-fx-fadeInScale"
        }
      >
        <div className="modal-background"></div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => {
            setAttivo(false);
          }}
        ></button>
        <div className="modal-content">
          <section className="modal-card-body" style={{ padding: 0 }}>
            <Img
              fluid={
                data.airtableProdotti.data.Immagine.localFiles[ig]
                  .childImageSharp.fluid
              }
            />
          </section>
        </div>
      </div>
    </>
  );
};

function creaTaglia(val) {
  let tag = "";
  val.map((opt) => (tag = tag + opt + "|"));
  let tagl = tag.slice(0, -1);
  return tagl;
}

function mapStateToProps(state) {
  return {
    lang: state.shop.lingua,
  };
}

export default connect(mapStateToProps)(Prodotto);

export const productQuery = graphql`
  query productPageQuery($slug: String!) {
    airtableProdotti(data: { slug: { eq: $slug } }) {
      id
      data {
        Peso
        Altezza
        Lunghezza
        Larghezza
        PrezzoOfferta
        ID
        COD
        Breve_it
        Descrizione_it
        PrezzoListino
        Nome_it
        slug
        Nome_en
        Nome_es
        Nome_fr
        Breve_en
        Breve_es
        Breve_fr
        Descrizione_en
        Descrizione_es
        Descrizione_fr
        Categorie
        Taglia
        Immagine {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Correlati {
          data {
            ID
            PrezzoOfferta
            PrezzoListino
            Nome_it
            slug
            Nome_en
            Nome_es
            Nome_fr
            Immagine {
              localFiles {
                childImageSharp {
                  id
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
