import React from "react"

import { owner } from "../../data"

const MisuraInvia = props => {
  const { lingua } = props

  return (
    
        <div>
          <div className="content">
            <p>{lingua.navigazione.misure.inviaParag1}</p>
            <p>{lingua.navigazione.misure.inviaParag2}</p>
            <p>{lingua.navigazione.misure.inviaParag3}</p>
          </div>
          <div className="content">
            <p className="has-text-weight-bold">{owner.azienda}</p>
            <p className="has-text-weight-bold">{owner.indirizzo}</p>
            <p className="has-text-weight-bold">
              {owner.citta}, {owner.provincia} {owner.cap}
            </p>
          </div>
        
          <div className="content">
            <p>{lingua.navigazione.misure.inviaTitolo2}</p>
            <ol type="1">
              <li>{lingua.navigazione.misure.inviaLista1}</li>
              <li>{lingua.navigazione.misure.inviaLista2}</li>
              <li>{lingua.navigazione.misure.inviaLista3}</li>
              <li>{lingua.navigazione.misure.inviaLista4}</li>
              <li>{lingua.navigazione.misure.inviaLista5}</li>
              <li>{lingua.navigazione.misure.inviaLista6}</li>
            </ol>
          </div>
        </div>
      
  )
}

export default MisuraInvia
