import React from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";

import moment from "moment";

import { lingua_it } from "../i18n/gdpr-it-IT";
import { lingua_en } from "../i18n/gdpr-en-GB";
import { lingua_es } from "../i18n/gdpr-es-ES";
import { lingua_fr } from "../i18n/gdpr-fr-FR";

import { owner } from "../data";

const PrivacyPolicy = (props) => {
  let lingua = {};

  switch (props.lang) {
    case "it-IT":
      lingua = lingua_it.privacy;
      break;
    case "fr-FR":
      lingua = lingua_fr.privacy;
      break;
    case "es-ES":
      lingua = lingua_es.privacy;
      break;
    case "en-GB":
      lingua = lingua_en.privacy;
      break;
    default:
      lingua = lingua_it.privacy;
  }

  moment.locale(lingua.lingua);

  return (
    <div className="section">
      <div className="container">
        <div className="content">
          <h1 className="title is-spaced is-3">{lingua.titolo}</h1>

          <h2 className="subtitle is-4">{lingua.sottotitolo_01}</h2>
          <p>
            {owner.appName} ({owner.appUrl})
          </p>
          <ul>
            <li>{lingua.testo_01}</li>
            <li>{lingua.testo_02}</li>
            <li>{lingua.testo_03}</li>
            <li>{lingua.testo_04}</li>
          </ul>

          <p>{lingua.testo_05}</p>
          <h2 className="subtitle is-4">{lingua.sottotitolo_02}</h2>
          <p>
            {lingua.testo_06} {owner.appName} {lingua.testo_07}
          </p>

          <h2 className="subtitle is-4">{lingua.sottotitolo_03}</h2>
          <p>
            {owner.appName} {lingua.testo_08}
          </p>
          <p>{lingua.testo_09}</p>

          <h2 className="subtitle is-4">{lingua.sottotitolo_04}</h2>
          <p>{lingua.testo_10}</p>
          <ul>
            <li>{lingua.testo_11}</li>
            <li>{lingua.testo_12}</li>

            <li>{lingua.testo_14}</li>
          </ul>

          <h2 className="subtitle is-4">{lingua.sottotitolo_05}</h2>
          <p>
            {owner.appName} {lingua.testo_15}
          </p>
          <ul>
            <li>
              {lingua.testo_16} {owner.appName} {lingua.testo_17}
            </li>
            <li>
              {lingua.testo_18} {owner.appName} {lingua.testo_19}
            </li>
          </ul>

          <h2 className="subtitle is-4">{lingua.sottotitolo_06}</h2>
          <p>{lingua.testo_20}</p>

          <h2 className="subtitle is-4">{lingua.sottotitolo_07}</h2>
          <p>{lingua.testo_21}</p>
          <p>{lingua.testo_22}</p>
          <ul>
            <li>{lingua.testo_23}</li>

            <li>{lingua.testo_25}</li>
          </ul>

          <h2 className="subtitle is-4">{lingua.sottotitolo_08}</h2>
          <p>{lingua.testo_26}</p>
          <p>{lingua.testo_27}</p>
          <p>{lingua.testo_28}</p>
          <p>{lingua.testo_29}</p>

          <h2 className="subtitle is-4">{lingua.sottotitolo_09}</h2>
          <p>{lingua.testo_30}</p>
          <p>{lingua.testo_31}</p>
          <ul>
            <li>
              Google Analytics (Google){" "}
              <a
                href={lingua.analytics}
                target="_blank"
                rel="noopener noreferrer"
              >
                {lingua.analytics}
              </a>
            </li>
          </ul>
          <p>{lingua.testo_32}</p>
          <ul>
            <li>
              {lingua.testo_33}{" "}
              <a href={lingua.shield} target="_blank" rel="noopener noreferrer">
                {lingua.shield}
              </a>
            </li>
            <li>
              {lingua.testo_34}{" "}
              <a
                href={lingua.garante}
                target="_blank"
                rel="noopener noreferrer"
              >
                {lingua.garante}
              </a>
            </li>
            <li>
              {lingua.testo_35}{" "}
              <a href={lingua.usa} target="_blank" rel="noopener noreferrer">
                {lingua.usa}
              </a>
            </li>
          </ul>
          <p>{lingua.testo_36}</p>

          <h2 className="subtitle is-4">{lingua.sottotitolo_10}</h2>
          <p>
            {lingua.testo_37} (
            <a href={lingua.link_01} target="_blank" rel="noopener noreferrer">
              {lingua.link_01}
            </a>
            ), {lingua.testo_37bis}
          </p>
          <ul>
            <li>{lingua.testo_38}</li>
            <li>{lingua.testo_39}</li>
            <li>{lingua.testo_40}</li>
            <li>{lingua.testo_41}</li>
            <li>{lingua.testo_42}</li>
            <li>{lingua.testo_43}</li>
            <li>{lingua.testo_44}</li>
            <li>
              {lingua.testo_45}{" "}
              <a
                href={lingua.link_02}
                target="_blank"
                rel="noopener noreferrer"
              >
                {lingua.link_02}
              </a>
              );
            </li>
            <li>{lingua.testo_46}</li>
          </ul>
          <p>{lingua.testo_47}</p>

          <h2 className="subtitle is-4">{lingua.sottotitolo_11}</h2>
          <p>
            {lingua.testo_48} {owner.gdpr.titolare}, {lingua.testo_49}{" "}
            <a href={"mailto:" + owner.gdpr.email}>{owner.gdpr.email}</a>
          </p>

          <h2 className="subtitle is-4">{lingua.sottotitolo_12}</h2>
          <p>
            {lingua.testo_50} {moment(owner.gdpr).format("LL")}.
          </p>

          <p>
            <Link to="/" className="uk-button uk-button-default" type="button">
              HOME
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    lang: state.shop.lingua,
  };
}

export default connect(mapStateToProps)(PrivacyPolicy);
