import React from "react"

const ShopDetail = props => {
  const { descr, titolo } = props

  return (
    <div style={{ marginBottom: "3rem" }}>
      <p className="title is-spaced is-4">{titolo}</p>
      <p
        dangerouslySetInnerHTML={{
          __html: descr,
        }}
      />
    </div>
  )
}
export default ShopDetail
