import React from "react"
import { connect } from "react-redux"
import { setopenmain } from "../../state/actions"

import { euro } from "../helpers"
import { Link } from "gatsby"

import Img from "gatsby-image"

const Card = props => {
  const { prezzo, prezzo_old, nome, codice, immagine } = props.tessuto.data
  const { tessuto } = props
  const { lingua, attivo, setOpenMain } = props
  const slug = props.tessuto.data.Path

  return (
    <Link to={`/${slug}/`} onClick={() => setOpenMain("")}>
      <div className="card">
        <div className="card-image" style={{ position: "relative" }}>
          <Img
            fluid={immagine.localFiles[0].childImageSharp.fluid}
            alt={nome}
          />

          {prezzo < prezzo_old && (
            <span
              style={{ position: "absolute", top: "0.75rem", right: 0 }}
              className="tag is-primary is-uppercase is-radiusless"
            >
              {lingua.inOfferta}
            </span>
          )}
        </div>
        <div
          className={
            attivo ? "card-content has-background-grey-lighter" : "card-content"
          }
          data-tooltip={tessuto.data[`colore_dettaglio_${lingua.lang}`]}
        >
          <div className="media">
            <div className="media-content">
              <p className="title is-7 is-uppercase">{nome}</p>

              <p className="subtitle is-7">{codice}</p>
              <p className="is-size-6 has-text-weight-semibold has-text-right">
                {prezzo < prezzo_old && (
                  <s
                    className="is-size-7"
                    style={{ marginRight: "0.75rem", opacity: "0.5" }}
                  >
                    {euro(prezzo_old)}
                  </s>
                )}
                {euro(prezzo)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    setOpenMain: val => dispatch(setopenmain(val)),
  }
}

export default connect(null, mapDispatchToProps)(Card)
