export function euro(prezzo) {
	const newPrezzo = prezzo.toString()
	const pos = newPrezzo.indexOf('.')
	if (pos !== -1) {
		const tryDec = newPrezzo.slice(-2).indexOf('.')
		if (tryDec !== -1) {
			return '€ ' + newPrezzo + '0'
		} else {
			return '€ ' + newPrezzo
		}
	} else {
		return '€ ' + newPrezzo + '.00'
	}
}

export function toInch(n) {
	let inches = (n * 0.393700).toFixed(2)
	return inches
}

export function orderid() {

	let now = Date.now().toString();

	// pad with additional random digits
	if (now.length < 14) {
		const pad = 14 - now.length;
		now += randomNumber(pad);
	}

	// split into xxxx-xxxxxx-xxxx format
	return [now.slice(0, 4), now.slice(4, 10), now.slice(10, 14)].join('-');

};

function randomNumber(length) {
	return Math.floor(
		Math.pow(10, length - 1) +
		Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
	).toString();
}

export function compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
  
      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];
  
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }