/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/modelli/dietro_1.glb");

  const { tessuto } = props;

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials["FABRIC 1_FRONT_275721"]}
        geometry={nodes.Body_Back_Yoke_Node.geometry}
      >
        <meshStandardMaterial map={tessuto} roughness={1} toneMapped={false} />
      </mesh>
      <mesh
        material={materials["FABRIC 1_FRONT_275721"]}
        geometry={nodes.Body_Back_2_Node.geometry}
      >
        <meshStandardMaterial map={tessuto} roughness={1} toneMapped={false} />
      </mesh>
      <mesh
        material={materials["FABRIC 1_FRONT_129538.001"]}
        geometry={nodes.Body_Back_1_Node001.geometry}
        position={[0, 0, -0.06]}
      >
        <meshStandardMaterial map={tessuto} roughness={1} toneMapped={false} />
      </mesh>
    </group>
  );
}

useGLTF.preload("/modelli/dietro_1.glb");
