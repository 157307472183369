import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Polso = props => {
  const { lingua, tipoTut } = props

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { regex: "/tutorial/polso.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
    {tipoTut === "corpo" ? (
      <div>
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt={lingua.tutorial.polso.titolo}
          style={{ marginBottom: "1rem" }}
        />
        <p className="title is-5 is-uppercase has-text-weight-semibold">
          {lingua.tutorial.polso.titolo}
        </p>
        <p className="is-size-6">{lingua.tutorial.polso.descrizione}</p>
      </div>
    ) : (
      <div>
        
        <p className="title is-5 is-uppercase has-text-weight-semibold">
          {lingua.tutorial.polsoCamicia.titolo}
        </p>
        <p className="is-size-6">{lingua.tutorial.polsoCamicia.descrizione}</p>
      </div>
    )}
  </>
  )
}

export default Polso
