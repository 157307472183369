/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/modelli/vela_collo_4_new.glb");

  const { tessuto, bottoni, asole, opa, matBottone, club } = props;

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, 0.32, 0.02]} rotation={[1.75, 0.18, -0.08]}>
        <mesh
          material={materials["Material5126.001"]}
          geometry={nodes.MatShape_12400_Node001.geometry}
          position={[0.034, 0.021, 0.035]}
          rotation={[-0.24, 0.18, -0.35]}
          scale={[0.41, 0.41, 0.41]}
        >
          <meshMatcapMaterial
            attach="material"
            matcap={matBottone}
            color={bottoni}
          />
        </mesh>
        <mesh
          material={materials["Material5126.001"]}
          geometry={nodes.MatShape_12400_Node002.geometry}
          position={[-0.048, 0.016, 0.02]}
          rotation={[0.02, 0.9, 0.2]}
          scale={[0.41, 0.41, 0.41]}
        >
          <meshMatcapMaterial
            attach="material"
            matcap={matBottone}
            color={bottoni}
          />
        </mesh>
      </group>
      <mesh
        material={materials["FABRIC 1_FRONT_28620"]}
        geometry={nodes.Collar_17_Node.geometry}
        position={[-0.0015, 0.347, -0.037]}
        rotation={[0.03, 0, 0]}
      >
        <meshStandardMaterial
          map={!club ? tessuto : null}
          roughness={1}
          toneMapped={false}
        />
      </mesh>
      <mesh
        material={materials["PT_FABRIC_FRONT_1815.006"]}
        geometry={nodes.MatShape_11510_Node.geometry}
        position={[-0.04, 0.29, 0.032]}
        rotation={[-0.14, 0.26, 1.46]}
        scale={[0.784, 0.995, 0.656]}
      >
        <meshStandardMaterial color={asole} transparent={true} opacity={opa} />
      </mesh>
      <mesh
        material={materials["PT_FABRIC_FRONT_1815.006"]}
        geometry={nodes.MatShape_11510_Node001.geometry}
        position={[0.04, 0.29, 0.03]}
        rotation={[-0.85, 1.17, 2.32]}
        scale={[0.784, 0.995, 0.656]}
      >
        <meshStandardMaterial color={asole} transparent={true} opacity={opa} />
      </mesh>
    </group>
  );
}

useGLTF.preload("/modelli/vela_collo_4_new.glb");
