import React, { useRef, useEffect } from "react"
import { connect } from "react-redux"
import { selscroll } from "../../../state/actions"

import { Scrollbars } from "react-custom-scrollbars"
import PrintCards from "../../PrintCards"

const ElencoTessuti = props => {
  const { posts, lingua, corrente, scroll, setScroll } = props

  const scrollb = useRef(null)

  useEffect(() => {
    scrollb.current.scrollTop(scroll)
  }, [scroll])

  const trovaScrollTop = () => {
    setScroll(scrollb.current.getScrollTop())
  }

  return (
    <>
      <Scrollbars
        universal
        autoHide
        style={{ height: "calc(100vh - 3.25rem)" }}
        className="is-hidden-touch is-hidden-desktop-only"
        ref={scrollb}
        onScrollStop={trovaScrollTop}
      >
        <PrintCards cards={posts} lingua={lingua} corrente={corrente} />
      </Scrollbars>

      <div className="is-hidden-widescreen">
        <PrintCards cards={posts} lingua={lingua} corrente={corrente} />
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    scroll: state.shop.scroll,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setScroll: val => dispatch(selscroll(val)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ElencoTessuti)
