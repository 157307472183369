import React from "react";
import { connect } from "react-redux";
import { setvista, setflagintera } from "../../state/actions";

import { RiOpenArmLine, RiSurgicalMaskLine } from "react-icons/ri";

const NavViste = (props) => {
  const { vista, setVis, flag, setFlag, lingua } = props;

  return (
    <>
      <div
        className="buttons is-hidden-touch is-hidden-desktop-only"
        style={{ position: "absolute", top: 0, left: "1rem" }}
      >
        <button
          className="button is-light"
          onClick={() => {setVis("intera");setFlag(true)}}
          style={{ background: "transparent" }}
        >
          <RiOpenArmLine
            style={
              vista === "intera"
                ? { fontSize: "2rem", color: "#363636" }
                : { fontSize: "2rem", color: "#aaa" }
            }
          />
        </button>

        <button
          className="button is-light"
          onClick={() => setVis("mask")}
          style={{ background: "transparent" }}
        >
          <RiSurgicalMaskLine
            style={
              vista === "mask"
                ? { fontSize: "2rem", color: "#363636" }
                : { fontSize: "2rem", color: "#aaa" }
            }
          />
        </button>


      </div>
      {flag===false && vista==="mask" &&
      <p className="bubble is-hidden-touch is-hidden-desktop-only">
        {lingua.bubble.prima}</p>
}
    </>

  );
};

function mapStateToProps(state) {
  return {
    
    flag: state.shop.flagIntera
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setVis: (val) => dispatch(setvista(val)),
    setFlag: (val) => dispatch(setflagintera(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavViste);
