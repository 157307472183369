import { createStore as reduxCreateStore } from "redux";

import { initialState } from "./initialState";

const LINGUA = "LINGUA";
const FLAGINTERA = "FLAGINTERA";
const FLAGLEGENDA = "FLAGLEGENDA";
const SCROLL = "SCROLL";
const CUSTOMIZATION = "CUSTOMIZATION";
const PERSONALIZZAZIONE = "PERSONALIZZAZIONE";
const OPENMAIN = "OPENMAIN";
const FILTRI = "FILTRI";
const FILTRIPERSONALIZZA = "FILTRIPERSONALIZZA";
const SETCOLORE = "SETCOLORE";
const SETDISEGNO = "SETDISEGNO";
const SETMATERIALE = "SETMATERIALE";
const SETBRAND = "SETBRAND";
const COLLETTO = "COLLETTO";
const POLSINO = "POLSINO";
const CHIUSURA = "CHIUSURA";
const CLUB = "CLUB";
const TASCHINO = "TASCHINO";
const CONTRASTO = "CONTRASTO";
const CONTRASTOCOLLETTO = "CONTRASTOCOLLETTO";
const CONTRASTOPOLSINO = "CONTRASTOPOLSINO";
const CONTRASTOCHIUSURA = "CONTRASTOCHIUSURA";
const ASOLA = "ASOLA";
const BOTTONE = "BOTTONE";
const FILO = "FILO";
const ELASTICO = "ELASTICO";
const MASK = "SETMASK";
const PINCE = "PINCE";
const VISTA = "VISTA";
const ATTIVAINIZIALI = "ATTIVAINIZIALI";
const INIZIALITESTO = "INIZIALITESTO";
const INIZIALISTILE = "INIZIALISTILE";
const INIZIALICOLORE = "INIZIALICOLORE";
const INIZIALIPOSIZIONE = "INIZIALIPOSIZIONE";
const TIPOMISURE = "TIPOMISURE";
const PRESAMISURE = "PRESAMISURE";
const AGGIORNAMISURE = "AGGIORNAMISURE";
const AGGIORNAFIT = "AGGIORNAFIT";
const SETROTAZIONE = "SETROTAZIONE";
const CAMERAORBIT = "CAMERAORBIT";
const FIELDOFVIEW = "FIELDOFVIEW";

const reducer = (state, action) => {
  switch (action.type) {
    case LINGUA:
      return {
        shop: {
          ...state.shop,
          lingua: action.payload,
        },
      };
    case FLAGINTERA:
      return {
        shop: {
          ...state.shop,
          flagIntera: action.payload,
        },
      };
    case FLAGLEGENDA:
      return {
        shop: {
          ...state.shop,
          flagLegenda: action.payload,
        },
      };
    case SCROLL:
      return {
        shop: {
          ...state.shop,
          scroll: action.payload,
        },
      };
    case CUSTOMIZATION:
      return {
        shop: {
          ...state.shop,
          customization: action.payload,
        },
      };
    case PERSONALIZZAZIONE:
      return {
        shop: {
          ...state.shop,
          personalizzazione: action.payload,
        },
      };
    case OPENMAIN:
      return {
        shop: {
          ...state.shop,
          openMain: action.payload,
        },
      };
    case FILTRI:
      return {
        shop: {
          ...state.shop,
          filtri: action.payload,
        },
      };
    case FILTRIPERSONALIZZA:
      return {
        shop: {
          ...state.shop,
          filtriPersonalizza: action.payload,
        },
      };
    case SETCOLORE:
      return {
        shop: {
          ...state.shop,
          colore: action.payload,
        },
      };
    case SETDISEGNO:
      return {
        shop: {
          ...state.shop,
          disegno: action.payload,
        },
      };
    case SETMATERIALE:
      return {
        shop: {
          ...state.shop,
          materiale: action.payload,
        },
      };
    case SETBRAND:
      return {
        shop: {
          ...state.shop,
          brand: action.payload,
        },
      };
    case COLLETTO:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            colletto: action.payload,
          },
        },
      };
    case POLSINO:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            polsino: action.payload,
          },
        },
      };
    case CLUB:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            club: action.payload,
          },
        },
      };
    case CHIUSURA:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            chiusura: action.payload,
          },
        },
      };
    case TASCHINO:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            taschino: action.payload,
          },
        },
      };
    case CONTRASTO:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            contrasto: action.payload,
          },
        },
      };
    case CONTRASTOCOLLETTO:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            contrastoColletto: action.payload,
          },
        },
      };
    case CONTRASTOPOLSINO:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            contrastoPolsino: action.payload,
          },
        },
      };
    case CONTRASTOCHIUSURA:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            contrastoChiusura: action.payload,
          },
        },
      };
    case ASOLA:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            asola: action.payload,
          },
        },
      };
    case BOTTONE:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            bottone: action.payload,
          },
        },
      };
    case FILO:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            filo: action.payload,
          },
        },
      };
    case ELASTICO:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            elastico: action.payload,
          },
        },
      };
    case MASK:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            mask: action.payload,
          },
        },
      };
    case PINCE:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            pince: action.payload,
          },
        },
      };
    case VISTA:
      return {
        shop: {
          ...state.shop,
          vista: action.payload,
        },
      };
    case SETROTAZIONE:
      return {
        shop: {
          ...state.shop,
          rotazione: action.payload,
        },
      };
    case CAMERAORBIT:
      return {
        shop: {
          ...state.shop,
          cameraOrbit: action.payload,
        },
      };
    case FIELDOFVIEW:
      return {
        shop: {
          ...state.shop,
          fieldOfView: action.payload,
        },
      };
    case ATTIVAINIZIALI:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            iniziali: {
              ...state.shop.camicia.iniziali,
              attiva: action.payload,
            },
          },
        },
      };
    case INIZIALITESTO:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            iniziali: {
              ...state.shop.camicia.iniziali,
              testo: action.payload,
            },
          },
        },
      };
    case INIZIALISTILE:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            iniziali: {
              ...state.shop.camicia.iniziali,
              stile: action.payload,
            },
          },
        },
      };
    case INIZIALICOLORE:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            iniziali: {
              ...state.shop.camicia.iniziali,
              colore: action.payload,
            },
          },
        },
      };
    case INIZIALIPOSIZIONE:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            iniziali: {
              ...state.shop.camicia.iniziali,
              posizione: action.payload,
            },
          },
        },
      };
    case TIPOMISURE:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            misure: {
              ...state.shop.camicia.misure,
              tipo: action.payload,
            },
          },
        },
      };
    case PRESAMISURE:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            misure: {
              ...state.shop.camicia.misure,
              [action.misura]: action.payload,
            },
          },
        },
      };
    case AGGIORNAMISURE:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            misure: {
              ...state.shop.camicia.misure,
              braccia: action.payload.braccia,
              collo: action.payload.collo,
              girovita: action.payload.girovita,
              lunghezza: action.payload.lunghezza,
              polso: action.payload.polso,
              spalle: action.payload.spalle,
              torace: action.payload.torace,
              taglia: action.payload.taglia,
            },
          },
        },
      };
    case AGGIORNAFIT:
      return {
        shop: {
          ...state.shop,
          camicia: {
            ...state.shop.camicia,
            misure: {
              ...state.shop.camicia.misure,
              fit: action.payload,
            },
          },
        },
      };

    default:
      return state;
  }
};

const createStore = () => reduxCreateStore(reducer, initialState);
export default createStore;
