export const lingua_fr = {
  lang: "fr",
  title: "3D SHIRT - Configurateur 3d de chemises personnalisé",
  description: "Configurateur 3d de chemises personnalisé",
  personalizza: "Personnaliser",
  misure: "Mesures",
  tessuto: "Tissu",
  filtri: "Filtres",
  scegliTessuto: "Choisir tissu",
  cercaTessuto: "Chercher du tissu...",
  inOfferta: "Offre Promotionnelle",
  nonDisponibile: "Non disponible",
  colore: "Couleur",
  colori: "Couleurs",
  tuttiColori: "Toutes les couleurs",
  bianco: "Blanc",
  celeste: "Bleu clair",
  altriColori: "Autres couleurs",
  disegno: "Motif",
  disegni: "Motifs",
  tuttiDisegni: "Toutes les motifs",
  tintaUnita: "Unie",
  righe: "Rayèe",
  quadretti: "Carreaux",
  fantasia: "Fantaisie",
  materiale: "Matière",
  materiali: "Matière",
  tuttiMateriali: "Toutes les matière",
  cotone: "Coton",
  lino: "Lin",
  brand: "Marque",
  brands: "Marques",
  tuttiBrands: "Toutes les Marques",
  codice: "Code",
  ordina: "Trier par prix",
  basso: "Par ordre croissant",
  alto: "Par ordre dècroissant",
  lato: "Vista laterale",
  front: "Vista frontale",
  retro: "Vista retro",
  piegata: "Vista piegata",
  extra: {
    tutti: "Tous les produits",
    selTaglia: "Choisissez la taille",
    desc: "Description",
    correlati: "Produits connexes",
  },
  bubble: {
    prima: "Clicca qui per tornare alla vista camicia.",
    seconda: "Trascina con il tasto SINISTRO del mouse per ruotare la camicia",
    terza: "Trascina con il tasto DESTRO del mouse per spostare la camicia.",
    quarta: "Muovi la ROTELLINA del mouse per lo zoom.",
    quinta: "OK, CHIUDI",
    sesta: "Trascina con un dito per ruotare la camicia.",
    settima: "Trascina con due dita per spostare la camicia.",
    ottava: "Allarga o stringi con due dita per lo zoom.",
  },
  banner: {
    titolo: "Utilisation de cookies",
    testo1:
      "Dans cette Application, nous utilisons des cookies (ou technologies similaires) pour stocker des informations sur votre ordinateur ou votre appareil mobile afin d'améliorer votre expérience en ligne. Les cookies sont de petits fichiers texte qui vous permettent de naviguer efficacement, de mémoriser vos préférences, de nous aider à comprendre les performances de l'Application et d'améliorer généralement votre expérience d'utilisateur.",
    testo2:
      "En fermant ce message, ou en naviguant sur le site, vous acceptez l'utilisation de cookies en fonction de vos préférences.",
    gestione: "Gestion des cookies",
    acconsento: "Je suis d'accord",
  },
  sito: {
    titolo: "Configurateur 3D pour chemises sur mesure",
    sottotitolo:
      "3D SHIRT est un configurateur 3D pour chemises sur mesure avec une interface simple, immédiate et engageante, capable d'offrir une perception réaliste du produit personnalisé.",
    bottone1: "Essayez le configurateur",
    titolo2: "Un configurateur 3D sans limites",
    sottotitolo2:
      "3D SHIRT vous permet d'avoir une expérience de configuration simple, rapide et engageante.",
    configura: "Configurer",
    titolo3: "Pourquoi utiliser le configurateur 3D",
    sottotitolo3:
      "Les avantages sont nombreux, tant pour l'utilisateur final que pour l'entreprise.",
    titolo4: "Qualité d'image",
    sottotitolo4:
      "Avec l'utilisation d'un software 3D pour le design de mode, sont obtenues des images réalistes sans précédent .",
    titolo5: "Extrême facilité d'utilisation ",
    sottotitolo5:
      "Exploitez le concept de gamification, c'est-à-dire tirez parti de l'interactivité et du plaisir pour atteindre l'objectif ultime.",
    titolo6: "Unicité du produit",
    sottotitolo6:
      "En personnalisant leur produit, l'utilisateur a le sentiment d'acheter quelque chose de spécial et d'unique, fait spécialement pour lui.",
    titolo7: "Vitesse de réponse",
    sottotitolo7:
      "Le configurateur utilise des technologies de pointe pour le développement d'applications, qui permettent d'obtenir des réponses à chaque action de l'utilisateur en temps réel.",
    titolo8: "Personnalisatione",
    sottotitolo8:
      "Le configurateur n'a pas de limites d'éléments à insérer et est 100% personnalisable avec sa propre marque et couleurs.",
    titolo9: " Web Marketing",
    sottotitolo9:
      "En offrant une expérience de navigation immersive, le configurateur contribue à augmenter les ventes et la notoriété de la marque.",
    titolo10: "Caractéristiques du configurateur de chemise 3D",
    sottotitolo10:
      "L'application guide l'utilisateur de la personnalisation du produit à l'achat.",
    titolo11: "Personnalisation de la chemise",
    sottotitolo11:
      "En quelques étapes simples, il sera possible de configurer la chemise sous tous ses aspects, depuis le choix du tissu et des éléments qui la composent, jusqu'à l'insertion des mesures.",
    titolo12: "Méthode d'achat",
    sottotitolo12:
      "L'application est capable de gérer toutes les étapes du processus d'achat, de la gestion du panier, et donc des produits et des codes de réduction, à la gestion de l'expédition et du paiement.",
    titolo13: "Création de compte",
    sottotitolo13:
      "L'application offre la possibilité de créer un profil personnel qui permettra à l'utilisateur de stocker ses mesures et ses achats.",
    newshop:
      "3D SHIRT permet la vente de tous les produits que vous exposez dans votre boutique de couture! Non seulement des chemises personnalisées mais aussi des chemises prêtes à l'emploi, des cravates, des accessoires ou tout ce que vous voulez!",
    last01: "Documentation juridique",
    last02:
      "3D SHIRT comprend une documentation juridique complète et à jour, qui peut être modifiée en fonction de vos besoins.",
    last03: "Multilingue",
    last04:
      "Avec 3D SHIRT, votre entreprise est projetée sur le marché international. L'application est déjà traduite en 4 langues: italien, anglais, français et espagnol. D'autres langues peuvent être intégrées sur demande.",
    last05: "Un e-commerce complet",
    last06:
      "3D SHIRT vous permet de présenter et de vendre tout type de produit, grâce à une  utile et complète section shop intégrée.",
    last07: "",
    last08:
      "3D SHIRT est le point de rencontre entre les technologies de l'information et la tradition riche et complexe de la couture artisanale. Un projet né en synergie avec des professionnels experts, pour répondre pleinement et efficacement à des besoins concrets. Tailleurs de traditions établies et  nouvelles entreprises utilisent 3Dshirt pour améliorer et consolider leur entreprise.",
  },
  contact: {
    titolo: "Envoyez nous un message",
    sottotitolo:
      "Contactez-nous pour demander un devis ou simplement pour des questions ou suggestions. Nous vous répondrons dans les plus brefs délais.",
    nome: "Votre nom",
    telefono: "Votre téléphone",
    email: "Votre email",
    messaggio: "Laisser un message",
    invia: "Envoyez le message",
  },
  header: {
    account: "Account",
    modificapassword: "Changez votre mot de Passe",
    logout: "Logout",
    wishlist: "Liste de Voeux",
    shopping: "Panier d'Achat",
    loggedOut: "Vous n'êtes plus connecté.",
    loggedOutError: "Une erreur est survenue:",
    cambiaPasswordSuccess:
      "Nous vous enverrons un email contenant un lien unique pour réinitialiser votre mot de passe.",
    cambiaPasswordError: "L'erreur suivante s'est produite:",
  },
  coloriTip: {
    grigio: "Gris",
    blu: "Bleu",
    nero: "Noir",
    marrone: "Marron",
    verde: "Vert",
    bianco: "Blanc",
    viola: "Pourpre",
    arancione: "Orange",
    beige: "Beige",
    giallo: "Jaune",
    rosa: "Rose",
    rosso: "Rouge",
  },
  footer: {
    account: "Account",
    login: "Login",
    logout: "Logout",
    cookie: "Cookie Policy",
    privacy: "Privacy Policy",
    termini: "Termes et Conditions",
    recesso: "Droit de Retrait",
    contattaci: "Contactez Nous",
    partita: "P.IVA",
  },
  dettaglio: {
    descrizione: "Description du tissu",
    comeMisure: "Comment prendre vos mesures",
    spedizione: "Expédition et retour",
  },
  navigazione: {
    lang: "fr",
    tessuto: "Tissu",
    scegliTessuto: "Choisir tissu",
    inOfferta: "Offre Promotionnelle",
    nonDisponibile: "Non disponible",
    personalizza: "Personnaliser",
    personalizzazione: "Personnalisation",
    polsinoGemelli: "Poignet manchette",
    contrastoColletto: "Appliquer au cou",
    contrastoPolsino: "Appliquer au poignet",
    contrastoChiusura: "Appliquer à la fermeture",
    chiusura: "Fermeture",
    colletto: "Cou",
    polsino: "Poignet",
    taschino: "Poches",
    pinces: "Pinces",
    asole: "Boutonnière",
    bottoni: "Boutons",
    fili: "Fil",
    contrasto: "Contraste",
    loading: "Chargement...",
    mascherina: "Masque",
    maniche: "Manches",
    notfound:
      "Il n'y a pas de tissus qui correspondent à vos sélections. Veuillez choisir différent filtres.",
    iniziali: {
      iniziali: "Initiales",
      inserisciIniziali: "Entrez vos initiales",
      tueIniziali: "Entrez vos initiales",
      stile: "Style des initiales",
      colore: "Couleur des initiales",
      bianco: "Blanc",
      blu: "Bleu",
      celeste: "Bleu clair",
      grigio: "Gris",
      nero: "Noir",
      rosso: "Rouge",
      verde: "Vert",
      arancione: "Orange",
      rosa: "Rose",
      posizione: "Choisir la position",
      sopra: "Haut",
      centro: "Centre",
      sotto: "Bas",
    },
    misure: {
      metodoTitolo: "Sélectionnez votre méthode",
      metodoTaglia: "Mesures à partir de tailles standard",
      metodoCorpo: "Mesurez votre corps",
      metodoCamicia: "Mesurez une chemise qui vous convient",
      metodoInvia: "Envoyez-nous une chemise pour la mesurer",
      metodoModifica: "Editer",
      metodoInformazioni: "Information",
      selezionaTaglia: "Choisissez votre taille...",
      selezionaVestibilita: "Choisissez votre coupe...",
      misure: "Mesures",
      tueMisure: "Vos mesures",
      salva: "Enregistrez vos mesures",
      errore: "Une erreur est survenue",
      successo: "Les mesures ont été enregistrées avec succès",
      Braccia: "Bras",
      Collo: "Cou",
      Girovita: "Taille",
      Polso: "Poignet",
      Spalle: "Epaules",
      Torace: "Poitrine",
      Lunghezza: "Longueur",
      vuoiAiuto: "Vous voulez de l'aide avec les mesures?",
      provaSelezionare: "Choisissez votre taille",
      successoCarica: "Les mesures ont été chargées avec succès",
      seleziona: "Sélectionner...",
      xs: "XS (Cou 38)",
      s: "S (Cou 38)",
      m: "M (Cou 39/40)",
      l: "L (Cou 41/42)",
      xl: "XL (Cou 43/44)",
      xxl: "XXL (Cou 45/46)",
      xxxl: "XXXL (Cou 47/48)",
      oppure: "ou",
      stoCaricando: "Chargement...",
      carica: "Chargez vos mesures",
      inviaTitolo: "Envoyez-nous la chemise à mesurer",
      inviaParag1:
        "Voulez-vous reproduire les mesures d'une chemise en votre possession, mais vous ne savez pas comment la mesurer correctement?",
      inviaParag2:
        "Envoyez-nous votre chemise et nos experts la mesureront et garderont vos mensurations pour les futurs achats. * Notez qu'aucun dommage sera causé à votre chemise et vous sera renvoyé de la meilleure façon possible.",
      inviaParag3: "Envoyer à:",
      inviaTitolo2: "Lors de la soumission, assurez-vous d'inclure:",
      inviaLista1: "La chemise que vous souhaitez que nous mesurions",
      inviaLista2: "Votre nom",
      inviaLista3: "Un numéro de téléphone",
      inviaLista4: "Votre adresse e-mail",
      inviaLista5: "L'adresse de livraison",
      inviaLista6: "Toutes instructions spéciales",
      infoTipoMisure: "Type de mesures:",
      infoTipoMisure0: "mesures par taille",
      infoTipoMisure1: "mesures du corps",
      infoTipoMisure2: "mesures de chemise",
      infoTipoMisure3: "J'enverrai une chemise pour la mesurer",
      infoTagliaSelezionata: "taille sélectionnée:",
      infoVestibilita: "Coupe",
      aggiungiIniziali: "Ajouter des initiales",
      stile1: "style",
      colore1: "coleur",
      posizione1: "emplacement",
    },
  },
  barraAcquisto: {
    lang: "fr",
    nonDisponibile: "Tissu non disponible.",
    tooltip: "Ajouter cette chemise au panier",
    carrello: "Ajouter au panier",
    camicia: "Chemise ",
    tessuto: "Tissu",
    di: " de",
    aggiunta: "La chemise a été ajoutée au panier",
    aggiunta1: "La chemise a été ajouté au panier.",
    aggiunta2: "Que veux-tu faire maintenant?",
    continua: "Continuer les achats",
    vai: "Aller au panier",
    wishlist: {
      lang: "fr",
      nuovoPrezzo: "Nouveau prix",
      nonDisponibile: "Attention, tissu non disponible!",
      wishlist: "Liste de souhaits",
      vuota: "Votre liste de souhaits est vide.",
      Camicia: "Chemise",
      tooltip: "Ajouter la chemise à la liste de souhaits",
      successo: "Chemise ajoutèe à la liste de souhaits",
      rimossa: "Chemise retiré à la liste de souhaits",
      Prodotto: "Produit",
      Prezzo: "Prix",
      Descrizione: "Description",
      Tessuto: "Tissu",
      Disegno: "Motif",
      Colletto: "Cou",
      Italiano: "Italien",
      "Italiano Classico": "Italien Classique",
      "Francese Classico": "Francais",
      "Francese Semiaperto": "Semi Francais",
      "Francese Semiaperto due bottoni": "Semi Francais deux boutons",
      "Button Down Classico": "Amèricain Button Down",
      "Button Down Alto dos botons": "Amèricain Button Down haute deux boutons",
      Diplomatico: "Cassè",
      Coreano: "Corèenne",
      "Polsino Arrotondato": "Poignet Ronde",
      "Polsino Smussato": "Poignet Carre",
      "Polsino Gemelli": "Poignet Manchette",
      "Manica Corta": "Manche Courte",
      Chiusura: "Fermeture",
      "Faldoncino Interno non Impunturato": "Simple",
      "Faldoncino Esterno Impunturato": "Americaine",
      "Finta Doppia e bottoni nascosti": "Cachè",
      "Nessun Taschino": "Sans Poches",
      "Taschino arrotandato": "Poche arrondie",
      "Taschino Smussato": "Poche carre",
      "Due Taschini Arrotondati": "Deux Poches arrondie",
      "Due Taschini Smussati": "Deux Poches carre",
      "Dietro Liscio": "Sans pinces",
      "Dietro con Doppie Pinces": "Double pinces",
      "Dietro con Doppia Piega": "Pinces au centre",
      "Asola Bianca": "Boutonnière blanc",
      "Asola Blu": "Boutonnière bleu",
      "Asola Celeste": "Boutonnière bleu clair",
      "Asola Grigia": "Boutonnière gris",
      "Asola Nera": "Boutonnière noir",
      "Asola Rossa": "Boutonnière rouge",
      "Bottone Bianco": "Boutons blanc",
      "Bottone Blu": "Boutons bleu",
      "Bottone Celeste": "Boutons bleu clair",
      "Bottone Grigio": "Boutons gris",
      "Bottone Nero": "Boutons noir",
      "Bottone Rosso": "Boutons rouge",
      "Filo Bianco": "Fil blanc",
      "Filo Blu": "Fil bleu",
      "Filo Celeste": "Fil bleu clair",
      "Filo Grigio": "Fil gris",
      "Filo nero": "Fil noir",
      "Filo rosso": "Fil rouge",
      Contrasto: "Contraste",
      contrastoPolsino: "Poignet",
      contrastoColletto: "Cou",
      contrastoChiusura: "Fermeture",
      Stile: "Style",
      Iniziali: "Initiales",
      iniStile: "Style",
      iniColore: "Couleur",
      iniPosition: "Emplacement",
      white: "Blanc",
      slateblue: "Bleu",
      lightsteelblue: "Bleu clair",
      lightgray: "Gris",
      black: "Noir",
      crimson: "Rouge",
      posizione: "Choisir la position ",
      sopra: "Haut",
      centro: "Centre",
      sotto: "Bas",
      rimuovi: "Supprimer de la Wishlist",
      importa: "importer dans le configurateur",
      torna: "Retour au configurateur",
    },
  },
  tutorial: {
    braccia: {
      header: "Bras",
      titolo: "Mesure du bras",
      descrizione:
        "Mesurez la manche de l'épaule au poignet en maintenant votre bras plié à 90°. Pour une mesure précise, il est nécessaire de mesurer de l’épaule au coude et de passer au bout du poignet, où il rejoint la main.",
    },
    bracciaCamicia: {
      header: "Bras",
      titolo: "Mesure du bras",
      descrizione:
        "Mesurez la manche de la couture la plus haute de l'épaule à l'extrémité du poignet, en gardant la chemise à plat sur une surface plane..",
    },
    collo: {
      header: "Cou",
      titolo: "Mesure du cou",
      descrizione:
        "Mesurez immédiatement sous la pomme d'Adam, en faisant glisser le metre ruban autour du cou pour prendre la circonférence. Il est important d’insérer un doigt entre la metre ruband et le cou pour assurer une prise confortable.",
    },
    colloCamicia: {
      header: "Cou",
      titolo: "Mesure du cou",
      descrizione:
        "Mesurez la distance entre le bouton du col et la boutonnière correspondante, en gardant le col ouvert et couché sur une surface plane.",
    },
    girovita: {
      header: "Taille",
      titolo: "Mesure de la taille",
      descrizione:
        "Mesurez la taille au point le plus large, à la hauteur du nombril. Si vous avez les hanches larges, il est conseillé de prendre la mesure légèrement plus bas, pour les mesurer au total. Pour un bon ajustement, vous devez respecter le tour de taille en laissant toujours un doigt de tolérance entre le corps et le ruban à mesurer pour pouvoir vous asseoir confortablement. Il est important de prendre des mesures précises tout en maintenant le ruban à mesurer bien en place sur le corps: l’ajustement sera donné par le style choisi lors de la conception de la chemise.",
    },
    girovitaCamicia: {
      header: "Taille",
      titolo: "Mesure de la taille",
      descrizione:
        "Mesurez la taille au point le plus large au niveau du nombril, entre les deuxième et troisième boutons à partir du bas, en gardant la chemise à plat sur une surface plane. Si vous avez des hanches larges, il est conseillé de prendre la mesure légèrement plus bas, pour les comprendre au total.",
    },
    polso: {
      header: "Poignet",
      titolo: "Mesure du poignet",
      descrizione:
        "Mesurez la circonférence du poignet au niveau de l'articulation. Pour un bon ajustement, vous devez faire rejoindre le ruban en tenant un doigt de tolérance entre le poignet et le ruban.",
    },
    polsoCamicia: {
      header: "Poignet",
      titolo: "Mesure du poignet",
      descrizione:
        "Mesurez la distance linéaire entre le bouton de la manchette et la boutonnière correspondante, en gardant la manchette ouvert et à plat sur une surface plane.",
    },
    spalle: {
      header: "Epaules",
      titolo: "Mesure des épaules",
      descrizione: "Mesurer l'épaule au point le plus large.",
    },
    spalleCamicia: {
      header: "Epaules",
      titolo: "Mesure des épaules",
      descrizione:
        "Mesurez la largeur des épaules au point le plus large, de la couture supérieure du bras gauche à celle du bras droit, en gardant la chemise à plat sur une surface plane.",
    },
    torace: {
      header: "Poitrine",
      titolo: "Mesures de la poitrine",
      descrizione:
        "Mesurer le tour de poitrine à la hauteur du mamelon. Pour un bon ajustement, vous devez rejoindre le ruban à mesurer sur la poitrine en tenant toujours un doigt de tolérance entre le corps et le ruban à mesurer pour pouvoir bouger librement.",
    },
    toraceCamicia: {
      header: "Poitrine",
      titolo: "Mesures de la poitrine",
      descrizione:
        "Mesurez la circonférence de la poitrine entre les deuxième et troisième boutons par le haut. Mesurez la distance linéaire entre le bouton et la boutonnière correspondante opposée.",
    },
    lunghezza: {
      header: "Longueur",
      titolo: "Mesure de la longueur",
      descrizione:
        "Incline la tête en avant et mesure à partir du centre de la base du cou, à l'os le plus saillant, jusqu'à 3/4 des fesses pour une longueur classique. Si vous portez une chemise, mesurez le dos à partir du centre de la couture du col jusqu'à l'ourlet du bas.",
    },
    lunghezzaCamicia: {
      header: "Longueur",
      titolo: "Mesure de la longueur",
      descrizione:
        "En gardant la chemise à plat sur une surface plane, mesurez à partir du centre de la base arrière du cou, au centre de la couture entre la chemise et le col, jusqu'à l'ourlet inférieur.",
    },
  },
  contatti: {
    richiesto: "Champs requis",
    emailInvalida: "Adresse email invalide",
    titolo: "Nous aimons vous parler",
    successo: "Merci. Votre message a été envoyé avec succès.",
    errore: "Attention. Une erreur est survenue.",
    nome: "Nom *",
    email: "Email *",
    oggetto: "Sujet *",
    messaggio: "Message *",
    attendere: "Attendre...",
    invia: "Envoyer",
    testo01:
      "CHAQUE COMMANDE EXIGE AU MOINS 20 JOURS OUVRABLES AVANT L’EXPÉDITION. Nous nous excusons de cette nuisance, mais votre chemise ne ressemblera pas à toutes les autres. ",
    testo02:
      "Sartoria Turrisi se trouve à Ceglie Messapica, une ville accueillante de la province de Brindisi, au sud des Pouilles.",
    testo03:
      "Depuis 1970, notre tradition vestimentaire se transmet de père en fils: nous sommes une petite entreprise artisanale qui travaille avec passion à toutes les étapes de la production, qui est réalisée exclusivement dans notre atelier de couture par des artisans Italiens hautement spécialisés.",
    testo04:
      "Le choix de tissus et d'accessoires de la plus haute qualité nous permet d'offrir l'excellence de l'authentique \"Made in Italy\".",
    testo05:
      "Notre objectif est de satisfaire notre client dans tous ses besoins, en mettant notre longue expérience à sa disposition.",
    testo06:
      "Il est aujourd'hui possible de confondre un sculpteur avec un tailleur, car tous deux créent des formes.",
    testo07: "KARL KRAUS",
    testo08:
      "Vous pouvez apprécier la qualité de nos tissus de vos propres mains.",
    testo09: "COMMANDEZ VOTRE KIT GRATUIT MAINTENANT!",
  },
  pagina404: {
    errore: "Error 404",
    nonEsiste: "La page que vous recherchez n'existe pas!",
    torna: "Retour au configurateur",
  },
};
