import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Girovita = props => {
  const { lingua, tipoTut } = props

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { regex: "/tutorial/girovita.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      {tipoTut === "corpo" ? (
        <div>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt={lingua.tutorial.girovita.titolo}
            style={{ marginBottom: "1rem" }}
          />
          <p className="title is-5 is-uppercase has-text-weight-semibold">
            {lingua.tutorial.girovita.titolo}
          </p>
          <p className="is-size-6">{lingua.tutorial.girovita.descrizione}</p>
        </div>
      ) : (
        <div>
          
          <p className="title is-5 is-uppercase has-text-weight-semibold">
            {lingua.tutorial.girovitaCamicia.titolo}
          </p>
          <p className="is-size-6">{lingua.tutorial.girovitaCamicia.descrizione}</p>
        </div>
      )}
    </>
  )
}

export default Girovita
