import React from "react";

import BloccoAcquisto from "../bloccoAcquisto";

const Dettaglio = (props) => {
  const { lingua, data, camicia } = props;

  return (
    <div className="column is-2-widescreen is-5 has-background-grey-paky is-hidden-mobile">
      <div className="section">
        <p className="title is-4">{data.nome}</p>
        <p className="subtitle is-5">{data.codice}</p>
        <p className="subtitle is-6 is-uppercase">{data.brand}</p>
        <p style={{ marginBottom: "2rem" }}>
          {data[`descrizione_${lingua.lang}`]}
        </p>

        <BloccoAcquisto data={data} lingua={lingua} camicia={camicia} />
      </div>
    </div>
  );
};

export default Dettaglio;
