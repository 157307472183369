import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { connect } from "react-redux";
import {
  setinitesto,
  setinistile,
  setinicolore,
  setiniposizione,
} from "../../state/actions";

import Img from "gatsby-image";

const InizialiSettings = (props) => {
  const immaginiIniziali = useStaticQuery(graphql`
    query {
      block: file(relativePath: { regex: "/iniziali/block.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      script: file(relativePath: { regex: "/iniziali/script.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      serif: file(relativePath: { regex: "/iniziali/serif.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sopra: file(relativePath: { regex: "/iniziali/sopra.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      centro: file(relativePath: { regex: "/iniziali/centro.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sotto: file(relativePath: { regex: "/iniziali/sotto.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const {
    lingua,
    testo,
    setTesto,
    stile,
    setStile,
    colore,
    setColore,
    posizione,
    setPosizione,
  } = props;

  return (
    <div className="section" style={{ background: "#fff" }}>

      <nav className="panel" >
        <p className="panel-heading" >
          {lingua.navigazione.iniziali.inserisciIniziali}
        </p>
        <div style={{ padding: "1.5rem" }}>
        
          <input
            
            value={testo}
            className="input is-rounded"
            type="text"
            aria-label="Search"
            placeholder={lingua.navigazione.iniziali.inserisciIniziali}
            onChange={(ev) => setTesto(ev.target.value)}
            maxLength="5"
          />
          
          
        
        </div>
      </nav>

      <nav className="panel" >
        <p className="panel-heading" >
          {lingua.navigazione.iniziali.stile}
        </p>
        <div style={{ padding: "1.5rem" }}>

          <div className="columns">
            <div className="column">
              <button className="buttonLink" onClick={() => setStile("block")}>
                <div className="card">
                  <div className="card-image">
                    <Img
                      fluid={immaginiIniziali.block.childImageSharp.fluid}
                      alt={"Block"}
                    />
                  </div>
                  <div
                    className={
                      stile === "block"
                        ? "card-content has-background-grey-lighter"
                        : "card-content"
                    }
                  >
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-7 is-uppercase">Block</p>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
            <div className="column">
              <button className="buttonLink" onClick={() => setStile("script")}>
                <div className="card">
                  <div className="card-image">
                    <Img
                      fluid={immaginiIniziali.script.childImageSharp.fluid}
                      alt={"Script"}
                    />
                  </div>
                  <div
                    className={
                      stile === "script"
                        ? "card-content has-background-grey-lighter"
                        : "card-content"
                    }
                  >
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-7 is-uppercase">Script</p>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
            <div className="column">
              <button className="buttonLink" onClick={() => setStile("serif")}>
                <div className="card">
                  <div className="card-image">
                    <Img
                      fluid={immaginiIniziali.serif.childImageSharp.fluid}
                      alt={"Serif"}
                    />
                  </div>
                  <div
                    className={
                      stile === "serif"
                        ? "card-content has-background-grey-lighter"
                        : "card-content"
                    }
                  >
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-7 is-uppercase">Serif</p>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </div>

        </div>
      </nav>


      <nav className="panel" >
        <p className="panel-heading" >
          {lingua.navigazione.iniziali.colore}
        </p>
        <div style={{ padding: "1.5rem" }}>
          <div className="columns">
            <div className="column">
              <button className="buttonLink" onClick={() => setColore(0)}>
                <div className="card">
                  <div
                    className={
                      colore === 0
                        ? "card-content inset has-background-white-ter"
                        : "card-content has-background-white-ter"
                    }
                  >
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-7 is-uppercase">
                          {lingua.navigazione.iniziali.bianco}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
            <div className="column">
              <button className="buttonLink" onClick={() => setColore(1)}>
                <div className="card">
                  <div
                    className={
                      colore === 1
                        ? "card-content inset has-background-link"
                        : "card-content has-background-link"
                    }
                  >
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-7 is-uppercase has-text-white-bis">
                          {lingua.navigazione.iniziali.blu}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
            <div className="column">
              <button className="buttonLink" onClick={() => setColore(2)}>
                <div className="card">
                  <div
                    className={
                      colore === 2
                        ? "card-content inset has-background-info"
                        : "card-content has-background-info"
                    }
                  >
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-7 is-uppercase has-text-white-bis">
                          {lingua.navigazione.iniziali.celeste}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <button className="buttonLink" onClick={() => setColore(3)}>
                <div className="card">
                  <div
                    className={
                      colore === 3
                        ? "card-content inset has-background-danger"
                        : "card-content has-background-danger"
                    }
                  >
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-7 is-uppercase has-text-white-bis">
                          {lingua.navigazione.iniziali.rosso}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
            <div className="column">
              <button className="buttonLink" onClick={() => setColore(4)}>
                <div className="card">
                  <div
                    className={
                      colore === 4
                        ? "card-content inset2 has-background-black-bis"
                        : "card-content has-background-black-bis"
                    }
                  >
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-7 is-uppercase has-text-white-bis">
                          {lingua.navigazione.iniziali.nero}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
            <div className="column">
              <button className="buttonLink" onClick={() => setColore(5)}>
                <div className="card">
                  <div
                    className={
                      colore === 5
                        ? "card-content inset has-background-grey"
                        : "card-content has-background-grey"
                    }
                  >
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-7 is-uppercase has-text-white-bis">
                          {lingua.navigazione.iniziali.grigio}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>

          </div>
          <div className="columns">
            <div className="column">
              <button className="buttonLink" onClick={() => setColore(6)}>
                <div className="card">
                  <div
                    className={
                      colore === 6
                        ? "card-content inset "
                        : "card-content "
                    }
                    style={{ background: 'darkorange' }}
                  >
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-7 is-uppercase has-text-white-bis">
                          {lingua.navigazione.iniziali.arancione}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>

            <div className="column">
              <button className="buttonLink" onClick={() => setColore(7)}>
                <div className="card">
                  <div
                    className={
                      colore === 7
                        ? "card-content inset"
                        : "card-content"
                    }
                    style={{ background: 'green' }}
                  >
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-7 is-uppercase has-text-white-bis">
                          {lingua.navigazione.iniziali.verde}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
            <div className="column">
              <button className="buttonLink" onClick={() => setColore(8)}>
                <div className="card">
                  <div
                    className={
                      colore === 8
                        ? "card-content inset "
                        : "card-content "
                    }
                    style={{ background: 'palevioletred' }}
                  >
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-7 is-uppercase has-text-white-bis">
                          {lingua.navigazione.iniziali.rosa}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>

      </nav>

      <nav className="panel" >
        <p className="panel-heading" >
          {lingua.navigazione.iniziali.posizione}
        </p>
        <div style={{ padding: "1.5rem" }}>
          <div className="columns">
            <div className="column">
              <button className="buttonLink" onClick={() => setPosizione(0)}>
                <div className="card">
                  <div className="card-image">
                    <Img
                      fluid={immaginiIniziali.sopra.childImageSharp.fluid}
                      alt={lingua.navigazione.iniziali.sopra}
                    />
                  </div>
                  <div
                    className={
                      posizione === 0
                        ? "card-content has-background-grey-lighter"
                        : "card-content"
                    }
                  >
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-7 is-uppercase">
                          {lingua.navigazione.iniziali.sopra}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
            <div className="column">
              <button className="buttonLink" onClick={() => setPosizione(1)}>
                <div className="card">
                  <div className="card-image">
                    <Img
                      fluid={immaginiIniziali.centro.childImageSharp.fluid}
                      alt={lingua.navigazione.iniziali.centro}
                    />
                  </div>
                  <div
                    className={
                      posizione === 1
                        ? "card-content has-background-grey-lighter"
                        : "card-content"
                    }
                  >
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-7 is-uppercase">
                          {lingua.navigazione.iniziali.centro}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
            <div className="column">
              <button className="buttonLink" onClick={() => setPosizione(2)}>
                <div className="card">
                  <div className="card-image">
                    <Img
                      fluid={immaginiIniziali.sotto.childImageSharp.fluid}
                      alt={lingua.navigazione.iniziali.sotto}
                    />
                  </div>
                  <div
                    className={
                      posizione === 2
                        ? "card-content has-background-grey-lighter"
                        : "card-content"
                    }
                  >
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-7 is-uppercase">
                          {lingua.navigazione.iniziali.sotto}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </nav>


    </div>
  );
};

function mapStateToProps(state) {
  return {
    testo: state.shop.camicia.iniziali.testo,
    stile: state.shop.camicia.iniziali.stile,
    colore: state.shop.camicia.iniziali.colore,
    posizione: state.shop.camicia.iniziali.posizione,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTesto: (val) => dispatch(setinitesto(val)),
    setStile: (val) => dispatch(setinistile(val)),
    setColore: (val) => dispatch(setinicolore(val)),
    setPosizione: (val) => dispatch(setiniposizione(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InizialiSettings);
