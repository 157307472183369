import React from "react"
import { connect } from "react-redux"
import { setpince, setopenmain } from "../../../state/actions"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { navPinces } from "../navigazione"

const Pinces = props => {
  const data = useStaticQuery(graphql`
    query MyPinces {
      allFile(sort: {fields: name}, filter: { absolutePath: { regex: "/pinces/img/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const { lingua, pinc, newPince, setOpenMain } = props

  const setPince = tipo => {
    newPince(tipo)
    setOpenMain("")
  }

  return (
    <div className="section">
      <div
        className="row columns is-multiline"
      >
        {navPinces.map(pince => (
          <div
            className="column is-half"
            key={pince._id}
          >
            <button className="buttonLink" onClick={() => setPince(pince._id)}>
              <div className={pinc === pince._id ? "card is-active" : "card"}>
                <div className="card-image">
                <Img
                    fluid={
                      data.allFile.edges[pince._id].node.childImageSharp
                        .fluid
                    }
                    alt={pince[`nome_${lingua.lang}`]}
                  />
                </div>
                <div
                  className={
                    pinc === pince._id
                      ? "card-content has-background-grey-lighter"
                      : "card-content"
                  }
                >
                  <div className="media">
                    <div className="media-content">
                    <p
                        className="title is-7 is-uppercase">
                        {pince[`nome_${lingua.lang}`]}
                      </p>

                      <p
                        className="subtitle is-7">Info pinces</p>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    pinc: state.shop.camicia.pince,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    newPince: val => dispatch(setpince(val)),
    setOpenMain: val => dispatch(setopenmain(val)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pinces)
