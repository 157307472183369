export const wrapTex = {
  "5557-101A": {
    repeat_x: 0.0052,
    repeat_y: 0.0082,
  },
  "3601-W1230-0021": {
    repeat_x: 0.032,
    repeat_y: 0.032,
  },
  "3091-W030-0387": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "5882S-W24409-0002": {
    repeat_x: 0.042,
    repeat_y: 0.042,
  },
  "2420-W831-0008": {
    repeat_x: 0.022,
    repeat_y: 0.022,
  },
  "Zaffiro-16": {
    repeat_x: 0.042,
    repeat_y: 0.042,
  },
  "3601-W7360-0008": {
    repeat_x: 0.032,
    repeat_y: 0.032,
  },
  "2420-W021-0008": {
    repeat_x: 0.032,
    repeat_y: 0.032,
  },
  "3601-W8880-0002": {
    repeat_x: 0.032,
    repeat_y: 0.032,
  },
  "3601-W7360-0007": {
    repeat_x: 0.032,
    repeat_y: 0.032,
  },
  "3985H-W24275-0001": {
    repeat_x: 0.03,
    repeat_y: 0.03,
  },
  "3985H-W24271-0001": {
    repeat_x: 0.035,
    repeat_y: 0.035,
  },
  "2640-W20800-0011": {
    repeat_x: 0.18,
    repeat_y: 0.18,
  },
  "2420-W841-0271": {
    repeat_x: 0.075,
    repeat_y: 0.075,
  },
  "Savoy-99": {
    repeat_x: 0.032,
    repeat_y: 0.032,
  },
  "Duca-208": {
    repeat_x: 0.012,
    repeat_y: 0.012,
  },
};

export const wrapCont = {
  "0": {
    repeat_x: 0.1,
    repeat_y: 0.1,
  },
  "1": {
    repeat_x: 0.025,
    repeat_y: 0.025,
  },
  "2": {
    repeat_x: 0.095,
    repeat_y: 0.095,
  },
  "3": {
    repeat_x: 0.065,
    repeat_y: 0.065,
  },
};
