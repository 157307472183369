import React, { useState } from "react"
import { connect } from "react-redux"
import { presamisure, aggiornamisure, aggiornafit } from "../../state/actions"

import Braccia from "../tutorial/braccia"
import Collo from "../tutorial/collo"
import Girovita from "../tutorial/girovita"
import Lunghezza from "../tutorial/lunghezza"
import Polso from "../tutorial/polso"
import Spalle from "../tutorial/spalle"
import Torace from "../tutorial/torace"

const MisuraSlider = props => {
  const {
    lingua,
    tipo,
    fit,
    sBraccia,
    sCollo,
    sGirovita,
    sLunghezza,
    sPolso,
    sSpalle,
    sTorace,
    setMisura,
    aggFit,
  } = props

  const handleInputChange = event => {
    setMisura([event.target.name], parseFloat(event.target.value))
  }
  const selectFit = event => {
    aggFit(event.target.value)
  }


  let tipoTut = "corpo"
  switch (tipo) {
    case "corpo":
      tipoTut = "corpo"
      break
    case "camicia":
      tipoTut = "camicia"
      break
    default:
      tipoTut = "corpo"
      break
  }

  const [foc, setFoc] = useState(<Braccia lingua={lingua} tipoTut={tipoTut} />)
  return (
    <div className="section">
      <div className="columns">
        <div className="column">
          <div>{foc}</div>
        </div>
        <div className="column">

          <div className="field" style={{ marginBottom: "1.5rem" }}>
            <label className="label">{lingua.navigazione.misure.selezionaVestibilita}</label>
            <div className="control">
              <div className="select is-primary">
                <select value={fit} onBlur={selectFit} onChange={selectFit}>
                  <option value="Slim">Slim</option>
                  <option value="Regular">Regular</option>
                  <option value="Large">Large</option>
                </select>
              </div>
            </div>
          </div>


          <div
            role="button"
            className="field"
            onFocus={() =>
              setFoc(<Braccia lingua={lingua} tipoTut={tipoTut} />)
            }
            onTouchStart={() =>
              setFoc(<Braccia lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">
                {lingua.navigazione.misure.Braccia}
              </label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="braccia"
                  className="is-fullwidth is-primary"
                  min="60"
                  max="75"
                  step="0.5"
                  value={sBraccia}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sBraccia + " cm"}</span>
              </p>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() => setFoc(<Collo lingua={lingua} tipoTut={tipoTut} />)}
            onTouchStart={() =>
              setFoc(<Collo lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">{lingua.navigazione.misure.Collo}</label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="collo"
                  className="is-fullwidth is-primary"
                  min="35"
                  max="55"
                  step="0.5"
                  value={sCollo}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sCollo + " cm"}</span>
              </p>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() =>
              setFoc(<Girovita lingua={lingua} tipoTut={tipoTut} />)
            }
            onTouchStart={() =>
              setFoc(<Girovita lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">
                {lingua.navigazione.misure.Girovita}
              </label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="girovita"
                  className="is-fullwidth is-primary"
                  min="78"
                  max="140"
                  step="0.5"
                  value={sGirovita}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sGirovita + " cm"}</span>
              </p>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() =>
              setFoc(<Lunghezza lingua={lingua} tipoTut={tipoTut} />)
            }
            onTouchStart={() =>
              setFoc(<Lunghezza lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">
                {lingua.navigazione.misure.Lunghezza}
              </label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="lunghezza"
                  className="is-fullwidth is-primary"
                  min="60"
                  max="100"
                  step="0.5"
                  value={sLunghezza}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sLunghezza + " cm"}</span>
              </p>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() => setFoc(<Polso lingua={lingua} tipoTut={tipoTut} />)}
            onTouchStart={() =>
              setFoc(<Polso lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">{lingua.navigazione.misure.Polso}</label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="polso"
                  className="is-fullwidth is-primary"
                  min="16"
                  max="32"
                  step="0.5"
                  value={sPolso}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sPolso + " cm"}</span>
              </p>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() => setFoc(<Spalle lingua={lingua} tipoTut={tipoTut} />)}
            onTouchStart={() =>
              setFoc(<Spalle lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">
                {lingua.navigazione.misure.Spalle}
              </label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="spalle"
                  className="is-fullwidth is-primary"
                  min="38"
                  max="65"
                  step="0.5"
                  value={sSpalle}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sSpalle + " cm"}</span>
              </p>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() => setFoc(<Torace lingua={lingua} tipoTut={tipoTut} />)}
            onTouchStart={() =>
              setFoc(<Torace lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">
                {lingua.navigazione.misure.Torace}
              </label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="torace"
                  className="is-fullwidth is-primary"
                  min="82"
                  max="140"
                  step="0.5"
                  value={sTorace}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sTorace + " cm"}</span>
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {

    fit: state.shop.camicia.misure.fit,

    sBraccia: state.shop.camicia.misure.braccia,
    sCollo: state.shop.camicia.misure.collo,
    sGirovita: state.shop.camicia.misure.girovita,
    sLunghezza: state.shop.camicia.misure.lunghezza,
    sPolso: state.shop.camicia.misure.polso,
    sSpalle: state.shop.camicia.misure.spalle,
    sTorace: state.shop.camicia.misure.torace,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setMisura: (nam, val) => dispatch(presamisure(nam, val)),
    aggMisure: val => dispatch(aggiornamisure(val)),
    aggFit: val => dispatch(aggiornafit(val)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MisuraSlider)
